import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Entrepreneur, ApiResponse, Terminal, Office } from '../../models';
import { ApiService, EnvService } from '../../services';
import {deepPropsExist} from '../../helpers';
@Injectable()
export class Entrepreneurs {
    entrepreneurs: Entrepreneur[] = [];
    sortTerminal: Terminal['id'] = '';
    sortSubsidiary: string = '';
    sortOffice: Office['id'] = '';
    constructor(private apiService: ApiService,
                private env: EnvService) {
    }
    query(params?: any) {
        if (!params) {
            return this.entrepreneurs;
        }
        return this.entrepreneurs.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }
    add(record: Entrepreneur) {
        this.entrepreneurs.unshift(record);
    }
    delete(record: Entrepreneur) {
        this.entrepreneurs.splice(this.entrepreneurs.indexOf(record), 1);
    }
    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        queryString ? queryString += `&limit=100` : queryString += `?limit=100`;
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }
    async recordCreate(record: Entrepreneur): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county,bank`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordUpdate(record: Entrepreneur, payload): Promise<ApiResponse> {
        // check if object has _id
        // tslint:disable-next-line:no-unused-expression
        deepPropsExist(record, '_id') ? record.id = record._id : '';
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }
    async employmentUpdate(record: Entrepreneur, payload): Promise<ApiResponse> {
        // tslint:disable-next-line:no-unused-expression
        deepPropsExist(record, '_id') ? record.id = record._id : '';
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs/employment/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('employmentUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }
    async approvalUpdate(record: Entrepreneur, payload): Promise<ApiResponse> {
        // tslint:disable-next-line:no-unused-expression
        deepPropsExist(record, '_id') ? record.id = record._id : '';
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs/approval/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('approvalUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=office,terminal,currentVehicle,role.permissions,state,county`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordPatch(record: Entrepreneur): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordDelete(record: Entrepreneur): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneurs/${record.id ? record.id : record._id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
    async sendOTP(record: any): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/entrepreneur/otp`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success) {
                    console.log('OTP sent');
                } else {
                    throwError(res.message);
                }
                return res;
            }));
        return await proRes.toPromise();
    }
}
