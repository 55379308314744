import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutes } from "./app.routing";
import {
  ApiService,
  AuthGuard,
  AuthService,
  DEFAULT_TIMEOUT,
  EnvService,
  ErrorInterceptor,
  InternetService,
  JwtInterceptor,
  TimeoutInterceptor,
} from "./services";

import {
  AccountHeadings,
  BankRegisters,
  Banks,
  BankTransactions,
  Categories,
  Cities,
  Counties,
  Customers,
  Expenses,
  Messages,
  Notifications,
  Partners,
  PmtRoutes,
  PosTransactions,
  Ratings,
  Settings,
  Staffs,
  States,
  Terminals,
  Tickets,
  Vehicles,
  Vouchers,
  PmlTerminals,
  PmlParcels,
  PmlPackages,
  PmlShipments,
  PmlSchedules,
  PmlRoutings,
  PmlImpounds,
  PmlVehicles,
  Offices,
  PmlAgents,
  PmlRegions,
  PmlZones,
  Roles,
  PmlStaffs,
  PmtVehicles,
  PmtTerminals,
  PmtBoardings,
  PmtSchedules,
  PmtReservations,
  PmtHirings,
  AccountClasses,
  BankAccounts,
  PosAccounts,
  GatewayPayments,
  Budgets,
  Entreprises,
  Entrepreneurs,
  PmtRegions,
  PmtAgents,
  Merchants,
  EcommerceCategories,
  PmtReports,
  DailyReports,
  PmlReports,
  Reports,
  BulkReports,
} from "./providers";

import { ConnectionServiceModule } from "ng-connection-service";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { LoginComponent } from "./pages/login/login.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginOtpComponent } from "./pages/login-otp/login-otp.component";
import { NotificationService } from "./services/notification.service";
import { PmlRoutes } from "./providers/pml/pml-routes";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { DataTablesModule } from "angular-datatables";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { PosAccount } from "./models";
import { IncomeManagerComponent } from "./pages/income/income-manager.component";
import { PmtAgentManagerComponent } from "./pages/pmt-agent/pmt-agent-manager/pmt-agent-manager.component";
import { Countries } from "./components/vector-map/vector-map.service";
import { MerchantManagerComponent } from "./pages/merchant/merchant-manager/merchant-manager.component";
import { EcommerceManagerComponent } from "./pages/ecommerce/ecommerce-manager/ecommerce-manager.component";
import { NgxLoadingModule } from "ngx-loading";
import { EnterpriseComponent } from "./pages/enterprise/enterprise.component";
import { PmtReportComponent } from "./pages/pmt/pmt-report/pmt-report.component";
import { PmlReportComponent } from "./pages/pml/pml-report/pml-report.component";
import { ReportSheetComponent } from "./components/report-sheet/daily-report-sheet.component";
// import { AnalyticReportComponent } from './analytic-report/analytic-report.component';
// import { ReportAddComponent } from './pages/analytics/report-add/report-add.component';
// import { AnalyticsComponent } from './pages/analytics/analytics.component';
// import { BulkReportComponent } from './pages/bulk-report/bulk-report.component';
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LoginOtpComponent,
    ResetPasswordComponent,
    AccountsComponent,
    EnterpriseComponent,
    IncomeManagerComponent,
    PmtAgentManagerComponent,
    MerchantManagerComponent,
    EcommerceManagerComponent,
    ReportSheetComponent,
    // AnalyticReportComponent,
    // ReportAddComponent,
    // AnalyticsComponent,
    // BulkReportComponent,
    // PmlReportComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
    }),
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    ConnectionServiceModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    DataTablesModule.forRoot(),
  ],
  providers: [
    AuthService,
    ApiService,
    AuthGuard,
    EnvService,
    InternetService,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
    AccountHeadings,
    Banks,
    Categories,
    Customers,
    Partners,
    Staffs,
    States,
    Cities,
    Counties,
    Vehicles,
    Ratings,
    PmtRoutes,
    BankRegisters,
    Messages,
    Notifications,
    Settings,
    Terminals,
    Vouchers,
    PosTransactions,
    BankTransactions,
    Tickets,
    Expenses,
    PmlTerminals,
    PmlParcels,
    PmlPackages,
    PmlShipments,
    PmlSchedules,
    PmlRoutings,
    PmlRoutes,
    PmlImpounds,
    PmlVehicles,
    Offices,
    PmlAgents,
    PmlRegions,
    PmlZones,
    Roles,
    PmlStaffs,
    PmtRoutes,
    PmtVehicles,
    PmtTerminals,
    PmtBoardings,
    PmtSchedules,
    PmtRoutes,
    PmtReservations,
    PmtHirings,
    AccountClasses,
    AccountHeadings,
    BankAccounts,
    PosAccounts,
    DatePipe,
    GatewayPayments,
    Budgets,
    Entreprises,
    Entrepreneurs,
    Countries,
    Counties,
    PmtRegions,
    PmtAgents,
    Merchants,
    EcommerceCategories,
    PmtReports,
    DailyReports,
    PmlReports,
    Reports,
    BulkReports,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
