import { Voucher } from "./voucher";
import { AccountHeading } from "./account-heading";
import { Staff } from "../people";
import { PmlTerminal } from "../pml";

export class Expense {
  id: string;
  code: string;
  terminal: PmlTerminal;
  subsidiary: string;
  amount: number;
  description: string;
  voucher: Voucher;
  expenseDate: Date;
  accountHeading: AccountHeading;
  createdBy: Staff;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: Staff;
  auditedStatus: string;
  auditedBy: Staff;
  auditedDate: Date;
  auditedRemark: string;
  constructor(fields: any) {
    // tslint:disable-next-line: forin
    for (const f in fields) {
      this[f] = fields[f];
    }
  }
}
