import { PmlRoutes } from '../../providers/pml/pml-routes';
import { PmtVehicle } from '../pmt';
import { Entrepreneur } from './entrepreneur';
import {Bank} from '../finance';

export class Entreprise {
    id: string;
    name: string;
    code: string;
    pmlRoutes: PmlRoutes[];
    entrepreneurs: Entrepreneur[];
    vehicles: PmtVehicle[];
    country: string;
    bank: Bank;
    bankAccountNumber: string;
    bankAccountName: string;
    remark: string;
    createdAt: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}
