import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BankTransaction, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()

export class BankTransactions {

    banktransactions: BankTransaction[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=terminal`;
        this.recordRetrieve(queryString).then(res => { this.banktransactions = res.payload; });
      }

    query(params?: any) {
        if (!params) {
            return this.banktransactions;
        }
        return this.banktransactions.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: BankTransaction) {
        this.banktransactions.push(record);
    }

    delete(record: BankTransaction) {
        this.banktransactions.splice(this.banktransactions.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/bank-transactions${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res));
        return await proRes.toPromise();
      }

}
