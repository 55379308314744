import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';
import { Category } from '../general/category';
import { PmtRoute } from './pmt-route';
import { PmtSchedule } from './pmt-schedule';
import { Vehicle } from '../factory';
import { Partner } from '../people';
import { County } from '../location';
import { Voucher } from '../finance';

export class PmtTransload {
    id: string;
    code: string;
    pmtRoute: PmtRoute;
    pmtscheduleFrom: PmtSchedule;
    pmtscheduleTo: PmtSchedule;
    vehicleFrom: Vehicle;
    vehicleTo: Vehicle;
    partnerFrom: Partner;
    partnerTo: Partner;
    nearestTerminal: Terminal;
    place: string;
    county: County;
    location: any;
    voucher: Voucher; // type = RETIREMENT
    manager: Staff;
    recovery: number;
    status: 'PENDING' | 'DISPATCHED' | 'TRANSLOADED' | 'CLOSED';
    remark: string;
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtTransload {
    id: string;
    code: string;
    pmtRoute: PmtRoute;
    pmtscheduleFrom: PmtSchedule;
    pmtscheduleTo: PmtSchedule;
    vehicleFrom: Vehicle;
    vehicleTo: Vehicle;
    partnerFrom: Partner;
    partnerTo: Partner;
    nearestTerminal: Terminal;
    place: string;
    county: County;
    location: any;
    voucher: Voucher;
    manager: Staff;
    recovery: number;
    status: 'PENDING' | 'DISPATCHED' | 'TRANSLOADED' | 'CLOSED';
    remark: string;
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
}

