import {Terminal} from "../location";
import {BankAccount} from "./bank-account";

export class PosTransaction {
  transactionRef: string;
  transactionDate: Date;
  accountNo: string;
  posTerminalId: string;
  posTerminalName: string;
  narration: string;
  amount: string;
  tranType: string;
  businessName: string;
  businessId: string;
  currency: string;
  bankName: string;
  //* process data
  code: string;
  bankAccount: BankAccount;
  subsidiary: string;
  terminal: Terminal

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }

}

export interface PosTransaction {
  transactionRef: string;
  transactionDate: Date;
  accountNo: string;
  posTerminalId: string;
  posTerminalName: string;
  narration: string;
  amount: string;
  tranType: string;
  businessName: string;
  businessId: string;
  currency: string;
  bankName: string;
  //* process data
  code: string;
  bankAccount: BankAccount;
  subsidiary: string;
  terminal: Terminal
}
