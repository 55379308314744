import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';
import { Category } from '../general/category';
import {PmtTerminal} from './pmt-terminal';

export class PmtRoute {
    id: string;
    name: string;
    terminalFrom: Terminal;
    pmtTerminalFrom: PmtTerminal;
    terminalTo: Terminal;
    pmtTerminalTo: PmtTerminal;
    category: Category;
    type: 'HIGHWAY' | 'SUBHIGHWAY' | 'LOCAL';
    fareClass1: number;
    fareClass2: number;
    fuelLitresClass1: number;
    fuelLitresClass2: number;
    distance?: number;
    partnerAllowance: number;
    duration: number; //* minutes
    trips: number;
    isReservable: boolean;
    isAvailable: boolean;

    dtoRepaymentPercent: number; // default: 54.0 },
    dtoServiceChargePercent: number; // default: 33.0 },
    dtoMaintenancePercent: number; // default: 13.0 },

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtRoute {
    [prop: string]: any;
    id: string;
    name: string;
    terminalFrom: Terminal;
    pmtTerminalFrom: PmtTerminal;
    terminalTo: Terminal;
    pmtTerminalTo: PmtTerminal;
    category: Category;
    type: 'HIGHWAY' | 'SUBHIGHWAY' | 'LOCAL';
    fareClass1: number;
    fareClass2: number;
    fuelLitresClass1: number;
    fuelLitresClass2: number;
    distance?: number;
    partnerAllowance: number;
    duration: number;
    trips: number;
    isReservable: boolean;
    isAvailable: boolean;

    dtoRepaymentPercent: number; // default: 54.0 },
    dtoServiceChargePercent: number; // default: 33.0 },
    dtoMaintenancePercent: number; // default: 13.0 },

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    deleted?: boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
}

