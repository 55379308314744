import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PmlImpound, ApiResponse, Terminal, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';


@Injectable()
export class PmlImpounds {

    pmlImpounds: PmlImpound[] = [];
    today = new Date().toISOString().slice(0, 10);
    user: Staff;
    terminalId = Terminal['id'];

    constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
        this.user = authService.getUser();
        this.terminalId = this.user.terminal.id;
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=pmlRoutings,pmlWaybill,pmtSchedule,sender,recipient`;
        queryString += `,terminalFrom,terminalTo,terminalStore,deliveryAgent,deliveryStaff,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.pmlImpounds = res.payload; });
    }

    query(params?: any) {
        if (!params) {
            return this.pmlImpounds;
        }
        return this.pmlImpounds.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmlImpound) {
        this.pmlImpounds.push(record);
    }

    delete(record: PmlImpound) {
        this.pmlImpounds.splice(this.pmlImpounds.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }


    async recordCreate(record: PmlImpound): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmlRoutings,pmlWaybill,pmtSchedule,sender,recipient`;
                    queryString += `,terminalFrom,terminalTo,terminalStore,deliveryAgent,deliveryStaff,createdBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlImpound, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmlRoutings,pmlWaybill,pmtSchedule,sender,recipient`;
                    queryString += `,terminalFrom,terminalTo,terminalStore,deliveryAgent,deliveryStaff,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlImpound): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlImpound): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async getCashRealized(queryString): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds/cash-realized${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res ));
        return await proRes.toPromise();
    }

    async operatePmlImpound(pmlShipment: PmlImpound, operate): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-impounds/operation/${pmlShipment.id}`;
        const proRes = this.apiService.updateApi(url, operate).pipe(
            map((res: ApiResponse) => res ));
        return await proRes.toPromise();
    }
}
