import { Customer } from '../crm/customer';
import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';
import { PmtSchedule } from '../pmt/pmt-schedule';
import { PmlRouting } from './pml-routing';
import { PmlPackage } from './pml-package';
import {PmlPartner} from './pml-partner';
import {PmlDriver} from './pml-driver';
import {PmlVehicle} from './pml-vehicle';
import {PmlParcel} from './pml-parcel';
import {PmlPackages} from '../../providers/pml';
import {PmlTerminal} from './pml-terminal';
import {PmlRoute} from './pml-route';
import {PmlSchedule} from './pml-schedule';

export class PmlShipment {
  id: string;
  code: string;
  custodian: string;
  pmlPartner: PmlPartner;
  pmlDriver: PmlDriver;
  pmlVehicle: PmlVehicle;
  vehicleSerial: string;
  pmlSchedule: PmlSchedule; // PmlSchedule[]
  pmlParcels: PmlParcel[];
  packageCount: number;
  pmlPackages: PmlPackage[];
  pmlRoutes: Array<PmlRoute>; // PmlRoute[]
  pmlTerminalFrom: PmlTerminal;
  pmlTerminalStore: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  pmlArrivals: any; //
  transportCharge: string;
  type: string;
  status: string;
  remark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;


  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }

}
