import {Office, Role} from '../people';
import {PmlTerminal} from './pml-terminal';

export class PmlStaff {
  id: string;
  title: string;
  surname: string;
  otherName: string;
  phone: string;
  phoneHome: string;
  email: string;
  emailPersonal: string;
  office: Office;
  role: Role;
  region: any; // PmlRegion
  zone: any; // PmlZone
  subsidiary: string;
  terminal: PmlTerminal;
  accessLevel: number;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlStaff {
  id: string;
  title: string;
  surname: string;
  otherName: string;
  phone: string;
  phoneHome: string;
  email: string;
  emailPersonal: string;
  office: Office;
  role: Role;
  region: any; // PmlRegion
  zone: any; // PmlZone
  subsidiary: string;
  terminal: PmlTerminal;
  accessLevel: number;
}
