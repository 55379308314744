import { PmtRoute } from './pmt-route';
import { Staff } from '../people';
import { PmtBoarding } from './pmt-boarding';
import { PmtReservation } from './pmt-reservation';
import { Partner } from '../people';
import { Terminal } from '../location';
import { PmtVehicle } from './pmt-vehicle';
import { PmtPartner } from './pmt-partner';
import { PmtTerminal } from './pmt-terminal';

export class PmtSchedule {
    _id: string;
    id: string;
    code: string;
    pmtTerminal: Terminal;
    pmtTerminals: PmtTerminal[];
    pmtVehicle: PmtVehicle;
    pmtRoutes: Array<PmtRoute>;
    pmtPartner: PmtPartner;
    partner?: Partner;
    boardingDate: Date;
    status: 'QUEUING'|'BOARDED'|'LOADED'|'WAYBILLED'|'DEPARTED'|'ARRIVED';
    isReservable: boolean;
    isFull: boolean;
    isLoaded: boolean;
    boardedDate: Date;
    boardedBy: Staff;
    loadedDate: Date;
    loadedBy: Staff; // checkedBy';
    arrivedDate: Date;
    arrivedBy: Staff;
    departedDate: Date;
    departedBy: Staff;
    waybilledDate: Date;
    waybilledBy: Staff; // settledBy';
    pmtReservations: Array<PmtReservation>;
    pmtBoardings: Array<PmtBoarding>;
    passengers: any[];
    fare: number;
    fareTotal: number;
    fuelAmount: number;
    partnerAllowance: number;
    isDto: boolean;
    dtoMaintenance: number;
    dtoRepayment: number;
    dtoServiceCharge: number;
    partnerReceivable: number; // totalPayout
    isCombine: boolean;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
        this.status = 'QUEUING';
    }

}

export interface PmtSchedule {
    id: string;
    code: string;
    terminal: Terminal;
    pmtTerminals: PmtTerminal[];
    pmtVehicle: PmtVehicle;
    pmtRoute: Array<PmtRoute>;
    partner?: Partner;
    boardingDate: Date;
    status: 'QUEUING'|'BOARDED'|'LOADED'|'WAYBILLED'|'DEPARTED'|'ARRIVED';
    isReservable: boolean;
    isFull: boolean;
    isLoaded: boolean;
    boardedDate: Date;
    boardedBy: Staff;
    loadedDate: Date;
    loadedBy: Staff; // checkedBy';
    arrivedDate: Date;
    arrivedBy: Staff;
    departedDate: Date;
    departedBy: Staff;
    waybilledDate: Date;
    waybilledBy: Staff; // settledBy';
    pmtReservations: Array<PmtReservation>;
    pmtBoardings: Array<PmtBoarding>;
    passengers: any[];
    fare: number;
    fareTotal: number;
    fuelAmount: number;
    partnerAllowance: number;
    isDto: boolean;
    dtoMaintenance: number;
    dtoRepayment: number;
    dtoServiceCharge: number;
    partnerReceivable: number; // totalPayout
    isCombine: boolean;
    remark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
