import {Partner, Staff} from '../people';
import {Assignment} from '../finance';
import {Rating} from '../crm';

export class PmlVehicle {
  id: string;
  code: string;
  name: string;
  custodian: 'PARTNER'|'STAFF'|'UNKNOWN';
  assignments: Assignment[];
  currentStaff?: Partner;
  currentPartner?: Partner;
  isAssigned: boolean;
  description: string;
  engineNumber?: string;
  chasisNumber?: string;
  plateNumber?: string;
  registrationNumber?: string;
  seatingCapacity?: number;
  vehicleType: 'BUS'|'CAR'|'TAXI'|'KEKE'|'BIKE'|'JEEP'|'UNKNOWN';
  vehicleMake:  'TOYOTA'|'UGAMA'|'MEIYER'|'SIENNA'|'KINGO'|'UNKNOWN';
  vehicleClass: 'FIRST'|'SECOND'|'THIRD'|'UNKNOWN';
  vehicleCategory: 'PMT'|'PATROL'|'PML'|'PET'|'PRESS'|'SHOP'|'OFFICIAL_PRIVATE'|'OFFICIAL_GENERAL'|'UNKNOWN';
  subsidiary: 'PMT'|'PML'|'PET'|'SHOP'|'PRESS'|'SPARES'|'ASSEMBLY'|'CHEMICAL'|'ENGINEERING'|'PEACEGROUP';
  vehicleLocation: 'OPERATION'|'IMPOUNDED'|'WORKSHOP'|'WAREHOUSE'|'SHOP'|'SCRAP'|'UNKNOWN';
  assetWorthiness: 'APPRECIATE'|'DEPRECIATE'|'UNKNOWN';
  color?: string;
  photo?: string;
  purchaseDate?: string;
  launchDate?: string;
  expiryDate?: string;
  lifespan?: number;
  purchaseValue?: number;
  openingValue?: number;
  closingValue?: number;
  salvageValue?: number;
  currentValue?: number;
  totalDepreciableCost?: number;
  depreciationRate?: number;
  depreciationExpense?: number;
  accumulatedDepreciation?: number;
  recordStatus?: 'PENDING'|'REJECTED'|'ACKNOWLEDGED'|'APPROVED'|'AUTHORIZED'|'AUDITED'|'CLOSED';
  approvedBy?: string;
  approvedDate?: string;
  remark?: string;
  isDto?: boolean;
  ownership: 'PMT'|'PARTNER';
  dtoInitialDeposit?: number;
  dtoMaintenance?: number;
  dtoRepayment?: number;
  isDtoSettled: boolean;
  isHealthy?: boolean;
  isActive?: boolean;
  ratings: Array<Rating>;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface PmlVehicle {
  iid: string;
  code: string;
  name: string;
  custodian: 'PARTNER'|'STAFF'|'UNKNOWN';
  assignments: Assignment[];
  currentStaff?: Partner;
  currentPartner?: Partner;
  isAssigned: boolean;
  description: string;
  engineNumber?: string;
  chasisNumber?: string;
  plateNumber?: string;
  registrationNumber?: string;
  seatingCapacity?: number;
  vehicleType: 'BUS'|'CAR'|'TAXI'|'KEKE'|'BIKE'|'JEEP'|'UNKNOWN';
  vehicleMake:  'TOYOTA'|'UGAMA'|'MEIYER'|'SIENNA'|'KINGO'|'UNKNOWN';
  vehicleClass: 'FIRST'|'SECOND'|'THIRD'|'UNKNOWN';
  vehicleCategory: 'PMT'|'PATROL'|'PML'|'PET'|'PRESS'|'SHOP'|'OFFICIAL_PRIVATE'|'OFFICIAL_GENERAL'|'UNKNOWN';
  subsidiary: 'PMT'|'PML'|'PET'|'SHOP'|'PRESS'|'SPARES'|'ASSEMBLY'|'CHEMICAL'|'ENGINEERING'|'PEACEGROUP';
  vehicleLocation: 'OPERATION'|'IMPOUNDED'|'WORKSHOP'|'WAREHOUSE'|'SHOP'|'SCRAP'|'UNKNOWN';
  assetWorthiness: 'APPRECIATE'|'DEPRECIATE'|'UNKNOWN';
  color?: string;
  photo?: string;
  purchaseDate?: string;
  launchDate?: string;
  expiryDate?: string;
  lifespan?: number;
  purchaseValue?: number;
  openingValue?: number;
  closingValue?: number;
  salvageValue?: number;
  currentValue?: number;
  totalDepreciableCost?: number;
  depreciationRate?: number;
  depreciationExpense?: number;
  accumulatedDepreciation?: number;
  recordStatus?: 'PENDING'|'REJECTED'|'ACKNOWLEDGED'|'APPROVED'|'AUTHORIZED'|'AUDITED'|'CLOSED';
  approvedBy?: string;
  approvedDate?: string;
  remark?: string;
  isDto?: boolean;
  ownership: 'PMT'|'PARTNER';
  dtoInitialDeposit?: number;
  dtoMaintenance?: number;
  dtoRepayment?: number;
  isDtoSettled: boolean;
  isHealthy?: boolean;
  isActive?: boolean;
  ratings: Array<Rating>;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
  deleted: boolean;
  deletedAt: Date;
  deletedBy: Staff;
}
