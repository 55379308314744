import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Supplier, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Suppliers {

  suppliers: Supplier[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
      let queryString = `?sort=name`;
      queryString += `&populate=products,createdBy,updatedBy`;
      this.recordRetrieve(queryString).then(res => { this.suppliers = res.payload; });      
  }

  query(params?: any) {
    if (!params) {
      return this.suppliers;
    }
    return this.suppliers.filter((store) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = store[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return store;
            } else if (field === params[key]) {
              return store;
            }
          }
      }
      return null;
    });
  }

  add(record: Supplier) {
    this.suppliers.push(record);
  }

  delete(record: Supplier) {
    const index = this.suppliers.findIndex(route => route.id === record.id);
    this.suppliers.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/suppliers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Supplier): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/suppliers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=products,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Supplier, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/suppliers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=products,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Supplier): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/suppliers/${record.id}`;
    const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Supplier): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/suppliers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
