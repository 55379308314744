import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, PosAccount } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PosAccounts {

    posAccounts: PosAccount[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=voucher,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.posAccounts = res.payload;  });
    }

    query(params?: any) {
        if (!params) {
            return this.posAccounts;
        }
        return this.posAccounts.filter((payroll) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = payroll[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return payroll;
                    } else if (field === params[key]) {
                        return payroll;
                    }
                }
            }
            return null;
        });
    }

    add(record: PosAccount) {
        this.posAccounts.push(record);
    }

    delete(record: PosAccount) {
        this.posAccounts.splice(this.posAccounts.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/bank/pos-accounts${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }


    async recordCreate(record: PosAccount): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pos-accounts`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=voucher,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PosAccount, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pos-accounts/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=voucher,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0])
                    })
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PosAccount): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pos-accounts/${record.id}`;
        const proRes = this.apiService.patchApi(url, {}).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PosAccount): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pos-accounts/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
