import { Staff } from '../people/staff';
import { Partner } from '../people/partner';
import { Vehicle } from '../factory/vehicle';
import { Task } from '../general/task';
import { Asset } from './asset';
import { Category } from '../general/category';

export class Assignment {
    id: string;
    code: string;
    category: Category;
    custodian: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    requestDate: Date; // desired date to receive to have the request granted
    task: Task;
    assetType: 'VEHICLE'|'INVENTORY' ;
    vehicle: Vehicle;
    asset: Asset;
    issuedDate: Date;
    issuedBy: Staff;
    issuerRemark: string;
    requestStatus: RequestStatus;
    status: AssignmentStatus;
    isReturnable: boolean;
    expectedReturnedDate: Date;
    actualReturnedDate: Date;
    collectedDate: Date;
    collectedBy: Staff; // Staff to received the returned asset
    collectedRemark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Assignment {
    id: string;
    code: string;
    category: Category;
    custodian: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    requestDate: Date; // category
    task: Task;
    assetType: 'VEHICLE'|'INVENTORY' ;
    vehicle: Vehicle;
    asset: Asset;
    issuedDate: Date;
    issuedBy: Staff;
    issuerRemark: string;
    requestStatus: RequestStatus;
    status: AssignmentStatus;
    isReturnable: boolean;
    expectedReturnedDate: Date;
    actualReturnedDate: Date;
    collectedDate: Date;
    collectedBy: Staff; // Staff to received the returned asset
    collectedRemark: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}


enum UserType { STAFF = 'STAFF', PARTNER = 'PARTNER', }

enum AssetType { VEHICLE = 'VEHICLE', INVENTORY = 'INVENTORY', }

enum AssignmentStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    ISSUED = 'ISSUED',
    COLLECTED = 'COLLECTED',
    REJECTED = 'REJECTED',
  }

  enum RequestStatus {
    PENDING = 'PENDING',
    COLLECTED = 'COLLECTED',
    REVOKED = 'REVOKED',
  }
