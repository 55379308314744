import { Expense } from "../finance/expense"

export class DailyReport {
  id?: string;
  income: string[];
  banking: string[];
  expense: Expense[];
  date: Date;

  totalIncome: number;
  totalExpense: number;
  balance: number;
  bankingTotal: number;
  remainingBalance: number;
  totalCostEstimate: any;
  totalCostPayable: any;
  totalCount: any;
  totalMass: any;
  totalQuantity: any;
  totalVolume: any;
  totalWorth: any;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface DailyReport {
  id?: string;
  income: string[];
  banking: string[];
  expense: Expense[];
  date: Date;

  totalIncome: number;
  totalExpense: number;
  balance: number;
  bankingTotal: number;
  remainingBalance: number;
}



