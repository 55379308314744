import {PmlTerminal} from './pml-terminal';
import {Staff} from '../people';

export class PmlBillingTier {
  id: string;
  type: string;
  density: number;
  billingTier1: number;
  billingTier2: number;
  billingTier3: number;
  billingTier4: number;
  billingTier5: number;
  createdBy: Staff;
  createdAt: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}
