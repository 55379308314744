import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { range, isEqual } from 'lodash';


@Component({
  selector: 'app-seat-positions',
  templateUrl: './seat-positions.component.html',
  styleUrls: ['./seat-positions.component.scss']
})
export class SeatPositionsComponent implements OnInit, OnChanges {
  @Input() disabledSeats: number[];
  @Input() numberOfSeats: number;
  @Input() activeSidebar: boolean;
  @Input() activatedSeats: number[];
  @Input() boardingId: string;
  @Output() selectSeats = new EventEmitter<number[]>();
  range = [];
  selectedSeats = [];
  previousNumberOfSeats = 0;
  prevBoardingID = '';


  constructor() { }

  ngOnInit() {
    this.range = range(1, this.numberOfSeats + 1);
    this.previousNumberOfSeats = this.numberOfSeats;
  }

  ngOnChanges() {
    if (this.numberOfSeats !== this.previousNumberOfSeats) {
      this.range = range(1, this.numberOfSeats + 1);
      this.previousNumberOfSeats = this.numberOfSeats;
    }

    if (!this.activeSidebar) {
      this.selectedSeats = [];
      const activeSeats = document.querySelectorAll('.active.seat-position');
      Array.prototype.slice.call(activeSeats).forEach(seat => {
        seat.classList.remove('active');
      });
    }

    if (!isEqual(this.activatedSeats, this.selectedSeats) || this.boardingId !== this.prevBoardingID) {
      this.selectedSeats = [...this.activatedSeats];
      this.prevBoardingID = this.boardingId;
    }
  }

  selectSeat(evt: Event, seatNo: number) {
    const target = evt.target as HTMLDivElement;
    if (target.classList.contains('disabled')) {
      return false;
    }

    if (target.classList.contains('active')) {
      this.selectedSeats.splice(this.selectedSeats.indexOf(seatNo), 1);
      target.classList.remove('active');
      this.selectSeats.emit(this.selectedSeats);
      return;
    }

    this.selectedSeats.push(seatNo);
    target.classList.add('active');
    this.selectSeats.emit(this.selectedSeats);
  }

}
