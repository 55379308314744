import { FactoryProduct } from '../factory/factory-product';
import { Staff } from '../people/staff';
import { Category } from '../general/category';

export class Fault {
    id: string;
    code: string;
    category: Category;
    name: string;
    manhour: number;
    spares: FactoryProduct[];
    diagnosis: string;
    repair: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Fault {
    id: string;
    code: string;
    category: Category;
    name: string;
    manhour: number;
    spares: FactoryProduct[];
    diagnosis: string;
    repair: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
