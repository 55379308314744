
import { Staff } from '../people/staff';
import { Category } from './category';
import { Office } from '../people/office';
import { Expense } from '../finance';

export class Report {
    id: string;
    code: string;
    type: string;
    name: string;
    category: Category;
    office: Office;
    description: string;
    subsidiary: Staff;
    income: string[];
    banking: string[];
    expense: Expense[];
    date: Date;
  
    totalIncome: number;
    totalExpense: number;
    balance: number;
    bankingTotal: number;
    remainingBalance: number;

    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // tslint:disable-next-line: forin
            for (const f in fields) {
                this[f] = fields[f];
            }
        }
    }

    export interface Report {
        id: string;
        code: string;
        type: string;
        name: string;
        category: Category;
        office: Office;
        description: string;
        subsidiary: Staff;
        income: string[];
        expense: Expense[];
        date: Date;
      
        totalIncome: number;
        totalExpense: number;
        balance: number;
        bankingTotal: number;
        remainingBalance: number;
        
        createdBy?: Staff;
        createdAt?: Date;
        updatedBy?: Staff;
        updatedAt?: Date;
    }
