import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, Offence } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Offences {

  offences: Offence[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
      let queryString = `?sort=-createdAt`;
      queryString += `&populate= category,offenceType,staff,partner`;
      this.recordRetrieve(queryString).then(res => { this.offences = res.payload; });
  }

  query(params?: any) {
    if (!params) {
      return this.offences;
    }
    return this.offences.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Offence) {
    this.offences.push(record);
  }

  delete(record: Offence) {
    this.offences.splice(this.offences.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/offences${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
          map((res: ApiResponse) => res ));
      return await proRes.toPromise();
  }

  async recordCreate(record: Offence): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/offences`;
      const proRes = this.apiService.postApi(url, record).pipe(
        map((res: ApiResponse) => {
            if (res.success && res.payload) {
                console.log('recordCreate() successful =>', res.payload);
                let queryString = `?_id=${res.payload.id}`;
                queryString += `&populate= category,offenceType,staff,partner`;
                this.recordRetrieve(queryString).then(res => {
                  this.add(res.payload[0])
                })
            }
            return res;
        }));
    return await proRes.toPromise();
}

  async recordUpdate(record: Offence, payload): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/offences/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
        map((res: ApiResponse) => {
            if (res.success && res.payload) {
                this.delete(record);
                console.log('recordUpdate() successful =>', res.payload);
                let queryString = `?_id=${res.payload.id}`;
                queryString += `&populate= category,offenceType,staff,partner`;
                this.recordRetrieve(queryString).then(res => {
                  this.add(res.payload[0])
                })
            }
            return res;
        }));
    return await proRes.toPromise();
}

  // Soft Delete
  async recordPatch(record: Offence): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/offences/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Offence): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/offences/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
