import {Staff} from './staff';
import {Rating} from '../crm';
import {Assignment, Bank} from '../finance';
import {County, State, Terminal} from '../location';
import {Office} from './office';
import {Role} from './role';
import {Entreprise} from './entreprise';

export class Entrepreneur {
    _id: string;
    id: string;
    serial?: string;
    title?: string;
    surname: string;
    otherName: string;
    fullName: string;
    gender: string;
    birthDate: Date;
    phone?: string;
    phoneHome?: string;
    address?: string;
    village?: string;
    state: State;
    county: County;
    country?: string;
    email?: string;
    password?: string;
    otp?: string;
    otpCount?: number;
    kin: string;
    kinPhone: string;
    kinAddress: string;
    guarantor1: string;
    guarantor1Phone: string;
    guarantor1Address: string;
    guarantor2?: string;
    guarantor2Phone?: string;
    guarantor2Address?: string;
    profession?: string;
    qualification?: string;
    institution?: string;
    tin?: number;
    bank?: Bank;
    bankAccountNumber?: string;
    bankAccountName?: string;
    rank?: string;
    office: Office;
    role: Role;
    entreprise: Entreprise;
    subsidiary?: string;
    terminal: Terminal;
    assignments: Assignment[];
    isAssignedVehicle?: boolean;
    notice?: string;
    ratings?: Array<Rating>;
    remark?: string;
    photo?: string;
    isSalaryPayable?: boolean;
    isDocumentComplete?: boolean;
    accessLevel?: string;
    //* Approval
    status: 'PENDING'|'APPROVED'|'REJECTED';
    approvedDate: Date;
    approvedBy: Staff;
    rejectedDate: Date;
    rejectedBy: Staff;
    approvalRemark: String;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: String;
    currentIp: String;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


}
