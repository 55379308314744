import { BankAccount } from './bank-account';
import { AccountClass } from './account-class';
import { Staff } from '../people/staff';

export class AccountHeading {
    id: string;
    code:	string;
    heading: string;
    accountClass: AccountClass;
    description:	string;
    amount: number;
    openingBalance: number;
    bankAccount: BankAccount;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface AccountHeading {
    id: string;
    code:	string;
    heading: string;
    accountClass: AccountClass;
    description:	string;
    amount: number;
    openingBalance: number;
    bankAccount: BankAccount;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
