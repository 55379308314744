import { Vehicle } from '../factory/vehicle';
import { PmtRoute } from '../pmt/pmt-route';
import { State } from '../location/state';
import { County } from '../location/county';
import { Partner } from '../people/partner';
import { Staff } from '../people/staff';


export class Accident {

    id: string;
    vehicle: Vehicle;
    partner: Partner;
    route: PmtRoute;
    state: State;
    county: County;
    occurredPlace: string;
    occurredDate: Date;
    gravity: string;
    nature: string;
    casualty: string;
    majorCause: string;
    minorCauses: string;
    collider: string;
    description: string;
    verdict: string;
    compensation: Number;
    remark: string;
    recordStatus: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
    
    
    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Accident {
    id: string;
    vehicle: Vehicle;
    partner: Partner;
    route: PmtRoute;
    state: State;
    county: County;
    occurredPlace: string;
    occurredDate: Date;
    gravity: string;
    nature: string;
    casualty: string;
    majorCause: string;
    minorCause: string;
    collider: string;
    description: string;
    verdict: string;
    compensation: Number;
    remark: string;
    recordStatus: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
