import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collection, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Collections {

  collections: Collection[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=items,customer,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.collections = res.payload; });
  }

query(params?: any) {
if (!params) {
  return this.collections;
}
return this.collections.filter((item) => {
  for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const field = item[key];
        if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
          return item;
        } else if (field === params[key]) {
          return item;
        }
      }
  }
  return null;
});
}

add(record: Collection) {
this.collections.push(record);
}

delete(record: Collection) {
this.collections.splice(this.collections.indexOf(record), 1);
}

async recordRetrieve(queryString = ''): Promise<ApiResponse> {
  const url = `${this.env.getCurrentUrl()}/erp/tables${queryString}`;
  const proRes = this.apiService.getApi(url).pipe(
    map((res: ApiResponse) => res));
  return await proRes.toPromise();
}

async recordCreate(record: Collection): Promise<ApiResponse> {
  const url = `${this.env.getCurrentUrl()}/erp/tables`;
  const proRes = this.apiService.postApi(url, record).pipe(
    map((res: ApiResponse) => {
      if (res.success && res.payload) {
        console.log('recordCreate() successful =>', res.payload);
        let queryString = `?_id=${res.payload.id}`;
        queryString += `&populate=createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => {
          this.add(res.payload[0])
        })
      }
      return res;
    }));
  return await proRes.toPromise();
}

async recordUpdate(record: Collection, payload): Promise<ApiResponse> {
  const url = `${this.env.getCurrentUrl()}/erp/tables/${record.id}`;
  const proRes = this.apiService.updateApi(url, payload).pipe(
    map((res: ApiResponse) => {
      if (res.success && res.payload) {
        this.delete(record);
        console.log('recordUpdate() successful =>', res.payload);
        let queryString = `?_id=${res.payload.id}`;
        queryString += `&populate=createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => {
          this.add(res.payload[0])
        })
      }
      return res;
    }));
  return await proRes.toPromise();
}

async recordPatch(record: Collection): Promise<ApiResponse> {
  const url = `${this.env.getCurrentUrl()}/erp/tables/${record.id}`;
  const proRes = this.apiService.patchApi(url, {}).pipe(
    map((res: ApiResponse) => {
      this.delete(record);
      return res;
    }));
  return await proRes.toPromise();
}

async recordDelete(record: Collection): Promise<ApiResponse> {
  const url = `${this.env.getCurrentUrl()}/erp/tables/${record.id}`;
  const proRes = this.apiService.deleteApi(url).pipe(
    map((res: ApiResponse) => {
      this.delete(record);
      return res;
    }));
  return await proRes.toPromise();
}
}
