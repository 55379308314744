import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { County, ApiResponse, State } from '../../models';
import { ApiService, EnvService } from '../../services';
import { States } from './states';


@Injectable()
export class Counties {

  counties: County[] = [];
  allStates: State[] = [];

  constructor(private apiService: ApiService, private env: EnvService, private states: States) {
    this.allStates = this.states.query();
    let queryString = `?sort=name`;
    queryString += `&populate=state`;
    this.recordRetrieve(queryString).then(res => { this.counties = res.payload; });
  }


  query(params?: any) {
    if (!params) {
      return this.counties;
    }
    return this.counties.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: County) {
    this.counties.push(record);
  }

  delete(record: County) {
    const index = this.counties.findIndex(county => county.id === record.id);
    this.counties.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/counties${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: County): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/erp/counties`;
      const proRes = this.apiService.postApi(url, record).pipe(
          map((res: ApiResponse) => {
              if (res.success && res.payload) {
                  const newState = this.allStates.find(state => state.id === res.payload.state);
                  const newPayload = {...res.payload, ...{state: newState}};
                  this.add(newPayload);
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordUpdate(record: County, payload): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/erp/counties/${record.id}`;
      const proRes = this.apiService.updateApi(url, payload).pipe(
          map((res: ApiResponse) => {
              if (res.success) {
                  this.delete(record);
                  const newState = this.allStates.find(state => state.id === res.payload.state);
                  const newPayload = {...res.payload, ...{state: newState}};
                  this.add(newPayload);
              } else {
                  throwError(res.message);
              }
              return res;
          }));
      return await proRes.toPromise();
  }

  async recordPatch(record: County): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/counties/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: County): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/counties/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
