import { Staff } from '../people/staff';
import { Terminal } from '../location/terminal';

export class BankRegister {
    id: string;
    code: string;
    transactionDate: Date;
    terminal: Terminal;
    subsidiary: string;
    depositor: Staff;
    amountDeposited: number;
    teller: string;
    acknowledgedDate: Date;
    acknowledgedBy: string;
    depositedDate: Date;
    authorizedDate: Date;
    amountRealized: number;
    recordStatus: string;
    remark: string;
    auditedStatus: string;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    deleted: boolean;
    deletedBy: Staff;
    deletedAt: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface BankRegister {
    // [prop: string]: any;
    id: string;
    code: string;
    transactionDate: Date;
    terminal: Terminal;
    subsidiary: string;
    depositor: Staff;
    amountDeposited: number;
    teller: string;
    acknowledgedDate: Date;
    depositedDate: Date;
    authorizedDate: Date;
    amountRealized: number;
    recordStatus: string;
    remark: string;
    auditedStatus: string;
    auditedBy: Staff;
    auditedDate: Date;
    auditedRemark: string;
    deleted: boolean;
    deletedBy: Staff;
    deletedAt: Date;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

