import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { LoginComponent } from "./pages/login/login.component";
import { LoginOtpComponent } from "./pages/login-otp/login-otp.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AuthGuard } from "./services";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { IncomeManagerComponent } from "./pages/income/income-manager.component";
import { PmtAgentManagerComponent } from "./pages/pmt-agent/pmt-agent-manager/pmt-agent-manager.component";
import { PmtManifestComponent } from "./pages/pmt-manifest/pmt-manifest.component";
import { MerchantManagerComponent } from "./pages/merchant/merchant-manager/merchant-manager.component";
import { EcommerceManagerComponent } from "./pages/ecommerce/ecommerce-manager/ecommerce-manager.component";
import { EnterpriseComponent } from "./pages/enterprise/enterprise.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "login-otp",
    component: LoginOtpComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./pages/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "customers",
        loadChildren: "./pages/customer/customer.module#CustomerModule",
      },
      {
        path: "ecommerce",
        component: EcommerceManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/ecommerce/ecommerce.module#EcommerceModule",
          },
        ],
      },
      {
        path: "accounts",
        component: AccountsComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/accounts/accounts.module#AccountsModule",
          },
        ],
      },
      {
        path: "pmt-agent",
        component: PmtAgentManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/pmt-agent/pmt-agent.module#PmtAgentModule",
          },
        ],
      },
      {
        path: "merchants",
        component: MerchantManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/merchant/merchant.module#MerchantModule",
          },
        ],
      },
      {
        path: "pmt-manifest",
        loadChildren:
          "./pages/pmt-manifest/pmt-manifest.module#PmtManifestModule",
      },
      {
        path: "bank-register",
        loadChildren:
          "./pages/bank-register/bank-register.module#BankRegisterModule",
      },

      // {
      //   path: "bulk-report",
      //   loadChildren: "./pages/bulk-report/bulk-report.module#BulkReportModule",
      // },

      {
        path: "expenses",
        loadChildren: "./pages/expenses/expenses.module#ExpensesModule",
      },
      {
        path: "voucher",
        loadChildren: "./pages/voucher/voucher.module#VoucherModule",
      },
      {
        path: "pmt",
        loadChildren: "./pages/pmt/pmt.module#PmtModule",
      },
      {
        path: "pml",
        loadChildren: "./pages/pml/pml.module#PmlModule",
      },
      {
        path: "income",
        component: IncomeManagerComponent,
        children: [
          {
            path: "",
            loadChildren: "./pages/income/income.module#IncomeModule",
          },
        ],
      },
      {
        path: "gateway-payments",
        loadChildren:
          "./pages/gateway-payments/gateway-payments.module#GatewayPaymentsModule",
      },
      {
        path: "budget",
        loadChildren: "./pages/budget/budget.module#BudgetModule",
      },
      {
        path: "enterprise",
        component: EnterpriseComponent,
        children: [
          {
            path: "",
            loadChildren:
              "./pages/enterprise/enterprise.module#EnterpriseModule",
          },
        ],
      },
      {
        path: "components",
        loadChildren:
          "./pages/components/components.module#ComponentsPageModule",
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#Forms",
      },
      {
        path: "tables",
        loadChildren: "./pages/tables/tables.module#TablesModule",
      },
      {
        path: "maps",
        loadChildren: "./pages/maps/maps.module#MapsModule",
      },
      {
        path: "widgets",
        loadChildren: "./pages/widgets/widgets.module#WidgetsModule",
      },
      {
        path: "charts",
        loadChildren: "./pages/charts/charts.module#ChartsModule",
      },
      {
        path: "calendar",
        loadChildren: "./pages/calendar/calendar.module#CalendarModulee",
      },
      {
        path: "user-profile",
        loadChildren:
          "./pages/user-profile/user-profile.module#UserProfileModule",
      },
      {
        path: "",
        loadChildren:
          "./pages/pages/user-profile/user-profile.module#UserModule",
      },
      {
        path: "",
        loadChildren: "./pages/pages/timeline/timeline.module#TimelineModule",
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/pages/pages.module#PagesModule",
      },
    ],
  },
];
