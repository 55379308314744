import {Component, ElementRef, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import * as printJS from 'print-js';
import { AuthService } from '../../services';
import { getFullname, safeGet } from '../../helpers';
import { PmtTerminal, Receipt, Staff } from '../../models';

export enum ReceiptType {
  INVOICE = 'Invoice',
  SLIP = 'Slip',
  TICKET = 'Ticket',
  WAYBILL = 'Waybill' // Partner
}

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})

export class ReceiptsComponent implements OnInit, AfterViewInit {

  @ViewChild('printArea', {static: false})  printArea: ElementRef;
   show = false;
   data: any = {
    receiptType: 'SLIP',
    dateIssued: new Date(),
    departureDate: new Date(),
    bearer: '',
    code: '',
    pmtRoute: 'From => To',
    seatQuantity: 0,
    seatPositions: '',
    vehicleNumber: 'xxxxxx',
    transportFee: 0.0,
    fareTotal: 0.0,
    fuelAmount: 0.0,
    partnerAllowance: 0.0,
    dtoRepayment: 0.0,
    dtoServiceCharge: 0.0,
    dtoMaintenance: 0.0,
    partnerReceivable: 0.0,
    tax: 0,
    netIncome: 0,
    totalPayout: 0,
  };

  user: Staff;
  terminalName: PmtTerminal['name'];

  constructor(authService: AuthService) {
    this.user = authService.getUser();
    this.terminalName = `${getFullname(this.user)} Agent`;
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
  }

  getRoute(route: string, dir: string = 'from'): string {
    const routes = route.split('=>').map((r: string) => r.trim());
    if (dir === 'from') {
      return routes[0];
    } else if (dir === 'to') {
      return routes[1];
    }
    return (`From: ${routes[0]}, To: ${routes[1]}`);
  }

  print(printData: any): void {
    this.data = printData;
    this.show = true;
    setTimeout(
      () => {
        printJS({printable: this.printArea.nativeElement.outerHTML, type: 'raw-html'});
        this.show = false;
      }, 3
    );
  }

}
