import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Entreprise, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';
@Injectable()
export class Entreprises {
    entreprises: Entreprise[] = [];
    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=table`;
        this.recordRetrieve(queryString).then(res => { this.entreprises = res.payload; });
    }
    query(params?: any) {
        if (!params) {
            return this.entreprises;
        }
        return this.entreprises.filter((entreprise) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = entreprise[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return entreprise;
                    } else if (field === params[key]) {
                        return entreprise;
                    }
                }
            }
            return null;
        });
    }
    add(record: Entreprise) {
        this.entreprises.push(record);
    }
    delete(record: Entreprise) {
        this.entreprises.splice(this.entreprises.indexOf(record), 1);
    }
    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/enterprises${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }
    async recordCreate(record: Entreprise): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/enterprises`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                console.log('recordCreate() successful =>', res.payload);
                this.add(res.payload);
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordUpdate(record: Entreprise, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/enterprises/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                this.add(res.payload);
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordPatch(record: Entreprise): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/enterprises/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
    async recordDelete(record: Entreprise): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/erp/enterprises/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
