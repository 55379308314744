import { County } from '../location';
import { Staff } from '../people/staff';
import { State } from '../location/state';
import {PmlTerminal, PmlZone, PmlRegion, PmlStaff} from '../pml';
import { Rating } from '../crm';
import {  Role } from '../people';
import { Bank } from '../finance';

export class PmlAgent {

    id: string;
    title: string;
    serial: string;
    terminals: PmlTerminal;
    driverLicence: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    address?: string;
    state?: State;
    county?: County;
    country: string;
    region: PmlRegion;
    zone: PmlZone;
    accessLevel: Number;
    password: string;
    otp: string;
    otpCount: Number;
    otpAccess: Boolean;
    email: string;
    phone?: string;
    phoneHome: string;
    kin: string;
    kinPhone: string;
    role: Role;
    kinAddress: string;
    guarantor1: string;
    guarantor1Address: string;
    guarantor1Phone: string;
    guarantor2: string;
    guarantor2Address: string;
    guarantor2Phone: string;
    photo?: string;
    qualification: string;
    institution: string;
    notice: string;
    ratings: Rating[];
    remark: string;
    bonus: string;
    bank: Bank;
    bankAccountNumber: string;
    bankAccountName: string;
    documents: string;
    isDocumentComplete: Boolean;
    status: string;
    approvedBy: Staff;
    approvedDate: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    disengagedBy: Staff;
    disengagedDate: Date;
    //* Authentication
    lastLogin: Date;
    currentLogin: Date;
    lastIp: string;
    currentIp: string;
    createdBy?: Staff;
    updatedBy?: Staff;
    deleted?: Boolean;
    deletedAt?: Date;
    deletedBy?: Staff;


    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface PmlAgent {
    [prop: string]: any;
    id: string;
    title: string;
    serial: string;
    terminals: PmlTerminal;
    driverLicence: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    maritalStatus: string;
    address?: string;
    state?: State;
    county?: County;
    country: string;
    region: PmlRegion;
    zone: PmlZone;
    accessLevel: Number;
    password: string;
    otp: string;
    otpCount: Number;
    otpAccess: Boolean;
    email: string;
    phone?: string;
    phoneHome: string;
    kin: string;
    kinPhone: string;
    role: Role;
    kinAddress: string;
    guarantor1: string;
    guarantor1Address: string;
    guarantor1Phone: string;
    guarantor2: string;
    guarantor2Address: string;
    guarantor2Phone: string;
    photo?: string;
    qualification: string;
    institution: string;
    notice: string;
    ratings: Rating[];
    remark: string;
    bonus: string;
    bank: Bank;
    bankAccountNumber: string;
    bankAccountName: string;
    documents: string;
    isDocumentComplete: Boolean;
    status: string;
    approvedBy: Staff;
    approvedDate: Date;
    verifiedBy: Staff;
    verifiedDate: Date;
    disengagedBy: Staff;
    disengagedDate: Date;
    lastLogin: Date;
    currentLogin: Date;
    lastIp: string;
    currentIp: string;
    createdBy?: Staff;
    updatedBy?: Staff;
    deleted?: Boolean;
    deletedAt?: Date;
    deletedBy?: Staff;
}

