import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as printJS from "print-js";
import { DailyReport } from "../../models";

export interface ReportObject {
  reportType: ReportType;
  reportData: DailyReport[];
  startDate?: Date;
  endDate?: Date;
}

export enum ReportType {
  DURATION = "Duration",
  REPORT = "Report",
}

@Component({
  selector: "app-daily-report-sheet",
  templateUrl: "./daily-report-sheet.component.html",
  styleUrls: ["./daily-report-sheet.component.css"],
})
export class ReportSheetComponent implements OnInit {
  @ViewChild("invoicePrintArea", null) printArea: ElementRef;
  show = false;
  data: ReportObject = {
    reportType: ReportType.REPORT,
    reportData: [],
    startDate: null,
    endDate: null,
  };

  grossCostEstimate: number;
  grossCostPayable: number;
  grossCount: number;
  grossMass: number;
  grossQuantity: number;
  grossVolume: number;
  grossWorth: number;

  constructor() {}

  ngOnInit() {}

  getRoute(route: string, dir: string = "from"): string {
    const routes = route.split("=>").map((r: string) => r.trim());
    if (dir === "from") {
      return routes[0];
    } else if (dir === "to") {
      return routes[1];
    }
    return `From: ${routes[0]}, To: ${routes[1]}`;
  }

  public print(data: ReportObject): void {
    this.data = data;
    this.show = true;
    this.grossCostEstimate = data.reportData
      .map((item) => item.totalCostEstimate)
      .reduce((a, b) => a + b);
    this.grossCostPayable = data.reportData
      .map((item) => item.totalCostPayable)
      .reduce((a, b) => a + b);
    this.grossCount = data.reportData
      .map((item) => item.totalCount)
      .reduce((a, b) => a + b);
    this.grossMass = data.reportData
      .map((item) => item.totalMass)
      .reduce((a, b) => a + b);
    this.grossQuantity = data.reportData
      .map((item) => item.totalQuantity)
      .reduce((a, b) => a + b);
    this.grossVolume = data.reportData
      .map((item) => item.totalVolume)
      .reduce((a, b) => a + b);
    this.grossWorth = data.reportData
      .map((item) => item.totalWorth)
      .reduce((a, b) => a + b);

    setTimeout(() => {
      printJS({
        printable: this.printArea.nativeElement.outerHTML,
        type: "raw-html",
        documentTitle: `Pml Report for date range of ${data.startDate}-${data.endDate}.pdf`,
      });
      this.show = false;
    }, 0);
  }
}
