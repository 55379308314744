import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BankRegister, ApiResponse, Terminal, Staff } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import { ID, safeGet } from '../../helpers';


@Injectable()
export class BankRegisters {

  bankRegisters: BankRegister[] = [];
  user: Staff;
  terminalId: Terminal['id'];
  subsidiary: string;

  constructor(authService: AuthService, private apiService: ApiService, private env: EnvService) {
    this.user = authService.getUser();
    this.terminalId = safeGet(this.user.terminal, 'id');
    this.subsidiary = this.user.subsidiary || 'PML';
    let queryString = `?sort=-createdAt`;
    if (this.terminalId !== ID.EMENE_TERMINAL) {
      queryString += `&terminal=${this.terminalId}`;
      queryString += `&subsidiary=${this.subsidiary}`;
    }
    queryString += `&populate=depositor,terminal,auditedBy,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.bankRegisters = res.payload; });
  }

  query(params?: any) {
    if (!params) {
      return this.bankRegisters;
    }
    return this.bankRegisters.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: BankRegister) {
    this.bankRegisters.push(record);
  }

  delete(record: BankRegister) {
    this.bankRegisters.splice(this.bankRegisters.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: BankRegister, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordAudit(record: BankRegister, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers/audit/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordAudit() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,auditedBy,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: BankRegister): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/bank-registers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
