import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Budget, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Budgets {

    budgets: Budget[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=terminal,office,accountHeading,category,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.budgets = res.payload; });
      }

  query(params?: any) {
    if (!params) {
      return this.budgets;
    }
    return this.budgets.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Budget) {
    this.budgets.push(record);
  }

  delete(record: Budget) {
    this.budgets.splice(this.budgets.indexOf(record), 1);
  }


  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/budgets${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }


  async recordCreate(record: Budget): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/budgets`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,office,accountHeading,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Budget, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/budgets/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminal,office,accountHeading,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Budget): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/budgets/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Budget): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/budgets/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
