import { Staff } from '../people/staff';
import { Category } from '../general/category';
import { Material } from './material';
import { FactoryProduct } from './factory-product';
import { Stage } from '../general/stage';
import { Task } from '../general/task';
import { Transfer } from '../general/transfer';

export class Production {

    id: string;
    code: string;
    category: Category;
	batch: string;
	materials: Array<{ material: Material, quantity: number }>;
	productsExpected: Array<{ product: FactoryProduct, quantity: number }>;
	productsRealized: Array<{ product: FactoryProduct, quantity: number }>;
	productsAvailable: Array<{ product: FactoryProduct, quantity: number }>;
	stages: Stage[];
	tasks: Task[];
	startDate: Date;
	endDate: Date;
	deadline: Date;
	budget: Number;
	manhour	: Number;
	transfer: Transfer
	subsidiary: string;
	status: "PENDING|ONGOING|COMPLETE"
	remark: string;
	manager	: Staff;
	createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Production {
    // [prop: string]: any;
	id: string;
    code: string;
    category: Category;
	batch: string;
	materials: Array<{ material: Material, quantity: number }>;
	productsExpected: Array<{ product: FactoryProduct, quantity: number }>;
	productsRealized: Array<{ product: FactoryProduct, quantity: number }>;
	stages: Stage[];
	tasks: Task[];
	startDate: Date;
	endDate: Date;
	deadline: Date;
	budget: Number;
	manhour	: Number;
	transfer: Transfer
	subsidiary: string;
	status: "PENDING|ONGOING|COMPLETE"
	remark: string;
	manager	: Staff;
	createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

