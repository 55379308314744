import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Staff, ApiResponse, Terminal, Office } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class Staffs {

  staffs: Staff[] = [];
  sortTerminal: Terminal['id'] = '';
  sortSubsidiary: string = '';
  sortOffice: Office['id'] = '';

  constructor(private apiService: ApiService,
        private env: EnvService) {
    let queryString = `?sort=-createdAt,terminal.name,surname`;
    queryString += `&populate=office,terminal,role.permissions`;
    this.recordRetrieve(queryString).then(res => { this.staffs = res.payload;  });
  }

  query(params?: any) {
    if (!params) {
      return this.staffs;
    }
    return this.staffs.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Staff) {
    this.staffs.push(record);
  }

  delete(record: Staff) {
    this.staffs.splice(this.staffs.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/staff${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

    
  async recordCreate(record: Staff): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/staff`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=office,terminal,role.permissions`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

async recordUpdate(record: Staff, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/staff/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=office,terminal,role.permissions`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
}

  async recordPatch(record: Staff): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/staff/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Staff): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/staff/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async sendOTP(record: any): Promise<ApiResponse> {
      const url = `${this.env.getCurrentUrl()}/erp/staff/otp`;
      const proRes = this.apiService.postApi(url, record).pipe(
        map((res: ApiResponse) => {
           if (res.success) {
               console.log('OTP sent');
           } else {
               throwError(res.message);
           }
           return res;
        }));
      return await proRes.toPromise();
  }
}
