import { Injectable } from "@angular/core";
import { Terminal, ApiResponse, PmtTerminal } from "../../models";
import { ApiService, EnvService } from "../../services";
import { Subject, throwError } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class PmtTerminals {
  terminals: Array<PmtTerminal> = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=name`;
    queryString += `&populate=manager,county,city,state,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then((res) => {
      this.terminals = res.payload;
    });
  }

  query(params?: any) {
    if (!params) {
      return this.terminals;
    }
    return this.terminals.filter((item) => {
      for (const key in params) {
        const field = item[key];
        if (
          typeof field == "string" &&
          field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
        ) {
          return item;
        } else if (field == params[key]) {
          return item;
        }
      }
      return null;
    });
  }

  add(record: PmtTerminal) {
    this.terminals.push(record);
  }

  delete(record: PmtTerminal) {
    this.terminals.splice(this.terminals.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ""): Promise<ApiResponse> {
    // const sub = queryString !== "" ? "&subsidiary=PMT" : "?subsidiary=PMT";
    const url = `${this.env.API_URL}/erp/terminals${queryString}`;
    console.log("Sending URL ==> ", url);
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtTerminal): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/terminals`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=manager,county,city,state,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtTerminal, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/terminals/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=manager,county,city,state,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: PmtTerminal): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/terminals/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtTerminal): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/terminals/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
