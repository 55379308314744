import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pmt-agent-manager',
  templateUrl: './pmt-agent-manager.component.html',
  styleUrls: ['./pmt-agent-manager.component.css']
})
export class PmtAgentManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
