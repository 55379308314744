import { Customer } from './customer';
import { Staff } from '../people/staff';

export class Ticket  {
    id: string;
    code: string;
    customer: Customer;
    complainant: string;
    respondent: string;
    type: string;
    subject: string;
    rating: string;
    status: string;
    remark: string;
    staff: Staff;
    messages: [any];
    complaint: string;
    priority: string;
    officerReply: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Ticket  {
    id: string;
    code: string;
    customer: Customer;
    complainant: string;
    respondent: string;
    type: string;
    rating: string;
    status: string;
    remark: string;
    staff: Staff;
    subject: string;
    messages: [any];
    complaint: string;
    priority: string;
    officerReply: boolean;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
