import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {  Pmldata, ApiResponse, DailyReport } from "../../models";
import { ApiService, EnvService } from "../../services";
@Injectable()
export class DailyReports {
  reports: DailyReport[] = [];
  today = new Date().toISOString().slice(0, 10);

  constructor(private apiService: ApiService, private env: EnvService) {
    const records = []; // Initial Values
    for (const item of records) {
      this.reports.push(new DailyReport(item));
    }
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=office,category,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then((res) => {
      this.reports = res.payload;
      console.log(res);
    });
  }

  query(params?: any) {
    if (!params) {
      return this.reports;
    }
    return this.reports.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: DailyReport) {
    this.reports.push(record);
  }

  delete(record: DailyReport) {
    this.reports.splice(this.reports.indexOf(record), 1);
  }

  async recordRetrieve(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/new/expenses/pml/transactions${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

 }
