import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Report, ApiResponse } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class Reports {
  // Retrieve(queryString: string) {
  //   throw new Error("Method not implemented.");
  // }

  reports: Report[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    const records = []; // Initial Values
    for (const item of records) {
      this.reports.push(new Report(item));
    }
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=office,category,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then((res) => {
      this.reports = res.payload;
    });
  }

  query(params?: any) {
    if (!params) {
      return this.reports;
    }
    return this.reports.filter((item) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = item[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return item;
          } else if (field === params[key]) {
            return item;
          }
        }
      }
      return null;
    });
  }

  add(record: Report) {
    const index = this.reports.findIndex((rate) => rate.id === record.id);
    this.reports.splice(index, 1);
  }

  delete(record: Report) {
    this.reports.splice(this.reports.indexOf(record), 1);
  }

  async recordRetrieve(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/expenses/pmt/transactions${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async retrieve(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/new/expenses/pml/transactions${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async Retrieve(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/expenses/pmt/transactions${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async record(queryString: string): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/new/expenses/pml/transactions${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }
  async recordCreate(record: Report): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/reports`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=office,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: Report, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/reports/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=office,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: Report): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/reports/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: Report): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/reports/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
