import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { 
      this.titleService.setTitle('ERP - Finance | Enterprise')
   }

  ngOnInit(): void {
  }

}
