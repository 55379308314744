import { Customer } from '../crm/customer';
import { PmtSchedule } from './pmt-schedule';
import { PmtRoute } from './pmt-route';
import { Staff } from '../people/staff';
import { Gateway } from '../finance';

export class PmtReservation {
    id: string;
    code: string;
    amount: number;
    gateway: Gateway;
    customer: Customer;
    description: string;
    destination:  string;
    seatQuantity: number;
    passenger: Customer;
    seatPositions?: [];
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    pmtBookings: string;
    transaction: any;
    pmtRoute: PmtRoute;
    pmtSchedule: PmtSchedule;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface PmtReservation {
    code: string;
    amount: number;
    gateway: Gateway;
    customer: Customer;
    description: string;
    destination:  string;
    seatQuantity: number;
    seatPositions?: [];
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    status: string;
    passenger: Customer;
    pmtBookings: string;
    transaction: any;
    pmtRoute: PmtRoute;
    pmtSchedule: PmtSchedule;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

