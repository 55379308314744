import { Customer } from '../crm/customer';
import { Staff } from '../people/staff';
import { Partner } from '../people/partner';
import { Supplier } from '../finance/supplier';
import { Category } from '../general/category';
import { Terminal } from '../location';

export class AncillaryRevenue {
    id: string;
    amount: number;
    code: string;
    transactionDetail: string; // flutterwave object
    customer: Customer;
    staff: Staff;
    partner: Partner;
    supplier: Supplier;
    userType: string;
    category: Category;
    type: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    description: string;
    subsidiary: string;
    terminal: Terminal;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }

}

export interface AncillaryRevenue {
    id: string;
    amount: number;
    code: string;
    transactionDetail: string; // flutterwave object
    customer: Customer;
    staff: Staff;
    partner: Partner;
    supplier: Supplier;
    userType: string;
    category: Category;
    type: string;
    paymentMethod: string;
    paymentGateway: string;
    paymentStatus: string;
    description: string;
    subsidiary: string;
    terminal: Terminal;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
