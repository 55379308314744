import { Staff } from '../people/staff';
import { Customer } from '../crm/customer';
import { Partner } from '../people/partner';
import {Supplier} from "../finance";

export class Message {
    id: string;
    sender?: 'STAFF'|'CUSTOMER'|'PARTNER';
    recipient?: 'STAFF'|'CUSTOMER'|'PARTNER';
    staffSender?: Staff;
    customerSender?: Customer;
    partnerSender?: Partner;
    supplierSender?: Supplier;
    staffSReceiver?: Staff;
    customerReceiver?: Customer;
    partnerReceiver?: Partner;
    supplierReceiver?: Supplier;
    subject?: string;
    body?: string;
    box?: 'INBOX|OUTBOX'
    receiveStatus?: 'UNREAD'|'READ';
    sentStatus?: 'DRAFT'|'SENT';
    createdBy?: Staff | Customer | Partner;
    createdAt?: Date;
    updatedBy?: Staff | Customer | Partner;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Message {
    id: string;
    sender?: 'STAFF'|'CUSTOMER'|'PARTNER';
    recipient?: 'STAFF'|'CUSTOMER'|'PARTNER';
    staffSender?: Staff;
    customerSender?: Customer;
    partnerSender?: Partner;
    supplierSender?: Supplier;
    staffSReceiver?: Staff;
    customerReceiver?: Customer;
    partnerReceiver?: Partner;
    supplierReceiver?: Supplier;
    subject?: string;
    body?: string;
    box?: 'INBOX|OUTBOX'
    receiveStatus?: 'UNREAD'|'READ';
    sentStatus?: 'DRAFT'|'SENT';
    createdBy?: Staff | Customer | Partner;
    createdAt?: Date;
    updatedBy?: Staff | Customer | Partner;
    updatedAt?: Date;
}
