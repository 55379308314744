
import { Terminal } from '../location/terminal';
import { Staff } from '../people/staff';

export class PmtAllocation {
    id: string;
    pmtMin: number;
    pmtMax: number;
    terminals: string;
    routeCategory: string;
    trackingManager: number;
    updatedBy: string;
    pushable: boolean;
    pullable: boolean;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface PmtAllocation {
    [prop: string]: any;

    id: string;
    pmtMin: number;
    pmtMax: number;
    terminals: string;
    routeCategory: string;
    trackingManager: number;
    updatedBy: string;
    pushable: boolean;
    pullable: boolean;
}
