import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiResponse, Voucher } from "../../models";
import { ApiService, EnvService } from "../../services";

@Injectable()
export class Vouchers {
  vouchers: Voucher[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
    queryString +=
      ",relatedVoucher,category,accountHeading,terminal,pmlTerminal,staff,partner";
    queryString +=
      ",acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy";
    this.recordRetrieve(queryString).then((res) => {
      this.vouchers = res.payload;
    });
  }

  query(params?: any) {
    if (!params) {
      return this.vouchers;
    }
    return this.vouchers.filter((voucher) => {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const field = voucher[key];
          if (
            typeof field === "string" &&
            field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0
          ) {
            return voucher;
          } else if (field === params[key]) {
            return voucher;
          }
        }
      }
      return null;
    });
  }

  add(record: Voucher) {
    this.vouchers.push(record);
  }

  delete(record: Voucher) {
    this.vouchers.splice(this.vouchers.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ""): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers${queryString}`;
    const proRes = this.apiService
      .getApi(url)
      .pipe(map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log("recordCreate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          // queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
          // queryString +=',relatedVoucher,category,accountHeading,terminal,staff,partner'
          // queryString +=',acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy'
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordUpdate(record: Voucher, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordUpdate() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
          queryString +=
            ",relatedVoucher,category,accountHeading,terminal,staff,partner";
          queryString +=
            ",acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy";
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordOperation(record: Voucher, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers/operation/${
      record.id
    }`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log("recordOperation() successful =>", res.payload);
          let queryString = `?_id=${res.payload.id}`;
          // queryString += `&populate=approvedBy,paidBy,receivedBy,checkedBy,auditedBy`;
          // queryString +=',relatedVoucher,category,accountHeading,terminal,staff,partner'
          // queryString +=',acknowledgedBy,endorsedBy,authorizedBy,createdBy,updatedBy'
          this.recordRetrieve(queryString).then((res) => {
            this.add(res.payload[0]);
          });
        }
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordPatch(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }

  async recordDelete(record: Voucher): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/new/vouchers/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      })
    );
    return await proRes.toPromise();
  }
}
