import { Staff } from '../people/staff';
import { Store } from '../general/store';
import { Supplier } from '../finance/supplier';
import { EcommerceCategory } from './category';
import { Rating } from '../crm/rating';

export class Product {
    id: string;
    name: string;
    code: string;
    sku: string;
    onSale: Boolean;
    model: string;
    make: string;
    type: string;
    color: string;
    grade: string;
    variants: Product[];
    size: string; //"L, M, XL, SM";
    category: EcommerceCategory;
    measure: string; //"Carton, Dozens, etc";
    unit: string;
    dimension: string; //"(Length * Width * Height) mm";
    volume: Number; //"dm3";
    mass: Number; //"Kg";
    area: Number; //"cm2";
    warranty: string; //"1 year";
    use: string; //"Application";
    parent: Product;
    splitGain: Number; //"No of Children parts obtain from splitting";
    reorderLevel: Number;
    reorderQuantity: Number;
    slashedPrice: Number;
    costPrice: Number;
    sellingPrice: Number;
    splittable: Boolean;
    depletion: Number; //"amount reduced per day";
    supplier: Supplier;
    description: string;
    subsidiary: string;
    remark: string;
    images: Array<{ type: string }>;
    attributes: Array<{ name: string, value: string; }>;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface Product {
    id: string;
    name: string;
    code: string;
    sku: string;
    onSale: Boolean;
    model: string;
    make: string;
    type: string;
    color: string;
    grade: string;
    variants: Product[];
    size: string; //"L, M, XL, SM";
    category: EcommerceCategory;
    measure: string; //"Carton, Dozens, etc";
    unit: string;
    dimension: string; //"(Length * Width * Height) mm";
    volume: Number; //"dm3";
    mass: Number; //"Kg";
    area: Number; //"cm2";
    warranty: string; //"1 year";
    use: string; //"Application";
    parent: Product;
    splitGain: Number; //"No of Children parts obtain from splitting";
    reorderLevel: Number;
    reorderQuantity: Number;
    slashedPrice: Number;
    costPrice: Number;
    sellingPrice: Number;
    splittable: Boolean;
    depletion: Number; //"amount reduced per day";
    supplier: Supplier;
    description: string;
    subsidiary: string;
    remark: string;
    images: Array<{ type: string }>;
    attributes: Array<{ name: string, value: string; }>;
    ratings: Rating[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
