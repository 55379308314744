import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, AncillaryRevenue } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class AncillaryRevenues {

    ancillaryRevenues: AncillaryRevenue[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=-createdAt`;
        queryString += `&populate=customer,staff,partner,supplier,category,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.ancillaryRevenues = res.payload; });
      }

  query(params?: any) {
    if (!params) {
      return this.ancillaryRevenues;
    }
    return this.ancillaryRevenues.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: AncillaryRevenue) {
    this.ancillaryRevenues.push(record);
  }

  delete(record: AncillaryRevenue) {
    this.ancillaryRevenues.splice(this.ancillaryRevenues.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ancillary-revenues${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: AncillaryRevenue): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ancillary-revenues`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=customer,staff,partner,supplier,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: AncillaryRevenue, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ancillary-revenues/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=customer,staff,partner,supplier,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: AncillaryRevenue): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ancillary-revenues/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: AncillaryRevenue): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/ancillary-revenues/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}
