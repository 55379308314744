export type pmtData = {
  date: string;
  income: {
    gateway: any;
    type: string;
    children: [];
    direction: string;
    pmlRoutings: [];
    costEstimate: number;
    costPayable: number;
    amount: number;
    seatPositions: [];
    paymentStatus: string;
    deliveryType: string;
    isReturned: boolean;
    packaged: boolean;
    deliveryStatus: string;
    routing: string;
    recipientConfirm: false;
    name: string;
    sender: string;
    recipient: string;
    packaging: string;
    description: string;
    terminalTo: string;
    departureDate: string;
    expectedDate: string;
    paymentMethod: string;
    identification: string;
    items: {
      id: string;
      name: string;
      quantity: number;
      mass: 1;
      volume: 1;
      category: string;
      worth: number;
      code: string;
    }[];

    terminalFrom: string;
    createdBy: string;
    creator: string;
    code: string;
    stateFrom: string;
    stateTo: string;
    locationFrom: {
      type: string;
      coordinates: number[];
      id: string;
      address: string;
    };
    locationTo: {
      type: string;
      coordinates: number[];
      id: string;
      address: string;
    };
    terminalStore: string;
    distance: number;
    duration: number;
    urgency: number;
    mass: number;
    volume: number;
    worth: number;
    quantity: number;
    senderPhone: string;
    senderEmail: string;
    recipientPhone: string;
    recipientEmail: string;
    trxref: string;
    createdAt: string;
    updatedAt: string;
    packagedBy: string;
    packagedDate: string;
    pmlPackage: string;
    id: string;
  }[];

  expense: {
    auditedStatus: string;
    terminal: string;
    subsidiary: string;
    code: string;
    expenseDate: string;
    amount: number;
    createdBy: string;
    voucher: string;
    createdAt: string;
    updatedAt: string;
    id: string;
  }[];
}[];

export const pmtData: pmtData = [];
