import { Terminal } from '../location/terminal';
import { PmlShipment } from './pml-shipment';
import { Staff } from '../people/staff';
import { Partner } from '../people/partner';
import { Vehicle } from '../factory/vehicle';
import { PmlPackage } from './pml-package';
import {PmlParcel} from './pml-parcel';
import {PmlDriver} from './pml-driver';
import {PmlVehicle} from './pml-vehicle';
import {PmlSchedule} from './pml-schedule';
import {PmlTerminal} from './pml-terminal';
import {PmlPartner} from './pml-partner';

export class PmlRouting {
  id: string;
  kind: 'SHIPMENT'|'WAYBILL';
  pmlParcel: PmlParcel;
  pmlShipment: PmlShipment;
  pmlPackage: PmlPackage;
  pmlSchedule: PmlSchedule;
  pmlTerminal: PmlTerminal;
  pmlTerminalTo: PmlTerminal;
  pmlTerminalFrom: PmlTerminal;
  pmlDriver: PmlDriver;
  pmtPartner: PmlPartner;
  pmlVehicle: PmlVehicle;
  pmtVehicle: Vehicle;
  vehicleSerial: string;
  driver: Staff;
  staffForwarding: Staff;
  staffReceiving: Staff;
  forwardingDate: Date;
  receivingDate: Date;
  forwardingCharge: number;
  remark: string;
  action: string;
  routingAction: 'RECEIVING'|'FORWARDING';
  status: 'PENDING'|'COMPLETE';
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }

}
