import { Terminal } from "../location/terminal";
import { Staff } from "../people/staff";
import { Partner } from "../people/partner";
import { Category } from "../general/category";
import { AccountHeading } from "./account-heading";
import { Supplier } from "../finance";
import { Bank } from "./bank";

export class Voucher {
  id: string;
  code: string;
  type: "PAYMENT" | "ADVANCE" | "RETIREMENT" | "TRANSFER";
  relatedVoucher?: Voucher;
  stage: number;
  category: Category;
  beneficiary: "STAFF" | "PARTNER" | "SUPPLIER";
  beneficiaryBank: Bank;
  beneficiaryAccName: string;
  beneficiaryAccNumber: string;
  staff: Staff;
  voucherDate: Date;
  partner: Partner;
  supplier: Supplier;
  accountHeading?: AccountHeading;
  terminal?: Terminal;
  subsidiary?: string;
  amount?: number;
  description?: string;
  paymentMethod: "GATEWAY" | "POS" | "CASH" | "CHEQUE" | "TRANSFER" | "USSD";
  paymentGateway:
    | "FLUTTERWAVE"
    | "INTERSWITCH"
    | "UNIONBANK"
    | "PAYSTACK"
    | "STRIPE"
    | "PAYPAL";
  paymentStatus: "PENDING" | "SUCCESSFUL" | "FAIL";
  rejectedBy: Staff; //! Stage-0
  rejectedDate: Date;
  acknowledgedBy: Staff; //! Stage-2 HoD
  acknowledgedDate: Date;
  approvedBy: Staff; //! Stage-3 Director
  checkedDate: Date;
  checkedBy: Staff; //! Stage-4 Audit
  approvedDate: Date;
  endorsedBy: Staff; //! Stage-5 Chairman
  endorsedDate: Date;
  authorizedBy: Staff; //! Stage-6 Finance
  authorizedDate: Date;
  paidBy: Staff; //! Stage-7 Cashier
  paidDate: Date;
  receivedBy: Staff;
  receivedDate: Date;
  auditedStatus: "PENDING" | "AUDITED" | "CLOSED" | "REJECTED";
  auditedBy: Staff; //! Stage-8,9 Internal & External Audit
  auditedDate: Date;
  auditedRemark: string;
  remark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      // @ts-ignore
      this[f] = fields[f];
    }
  }
}

export interface Voucher {
  id: string;
  code: string;
  type: "PAYMENT" | "ADVANCE" | "RETIREMENT" | "TRANSFER";
  relatedVoucher?: Voucher;
  stage: number;
  category: Category;
  beneficiary: "STAFF" | "PARTNER" | "SUPPLIER";
  beneficiaryBank: Bank;
  beneficiaryAccName: string;
  beneficiaryAccNumber: string;
  staff: Staff;
  partner: Partner;
  supplier: Supplier;
  accountHeading?: AccountHeading;
  terminal?: Terminal;
  voucherDate: Date;
  subsidiary?: string;
  amount?: number;
  description?: string;
  paymentMethod: "GATEWAY" | "POS" | "CASH" | "CHEQUE" | "TRANSFER" | "USSD";
  paymentGateway:
    | "FLUTTERWAVE"
    | "INTERSWITCH"
    | "UNIONBANK"
    | "PAYSTACK"
    | "STRIPE"
    | "PAYPAL";
  paymentStatus: "PENDING" | "SUCCESSFUL" | "FAIL";
  rejectedBy: Staff; //! Stage-0
  rejectedDate: Date;
  acknowledgedBy: Staff; //! Stage-2 HoD
  acknowledgedDate: Date;
  approvedBy: Staff; //! Stage-3 Director
  checkedDate: Date;
  checkedBy: Staff; //! Stage-4 Audit
  approvedDate: Date;
  endorsedBy: Staff; //! Stage-5 Chairman
  endorsedDate: Date;
  authorizedBy: Staff; //! Stage-6 Finance
  authorizedDate: Date;
  paidBy: Staff; //! Stage-7 Cashier
  paidDate: Date;
  receivedBy: Staff;
  receivedDate: Date;
  auditedStatus: "PENDING" | "AUDITED" | "CLOSED" | "REJECTED";
  auditedBy: Staff; //! Stage-8,9 Internal & External Audit
  auditedDate: Date;
  auditedRemark: string;
  remark: string;
  createdBy?: Staff;
  createdAt?: Date;
  updatedBy?: Staff;
  updatedAt?: Date;
}
