import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, ApiResponse, Terminal, State, County, Stock } from '../../models';
import { ApiService, EnvService } from '../../services';
import { deepPropsExist } from '../../helpers';
import { Terminals } from '../location/terminals';
import { States } from '../location/states';
import { Counties } from '../location/counties';


@Injectable()
export class Stocks {

  stocks: Stock[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    const records = []; // Initial Values
    for (const item of records) {
      this.stocks.push(new Stock(item));
    }
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=store,asset,material,product,vehicle,createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.stocks = res.payload;  });
  }

  query(params?: any) {
    if (!params) {
      return this.stocks;
    }
    return this.stocks.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: Stock) {
    this.stocks.push(record);
  }

  delete(record: Stock) {
    const index = this.stocks.findIndex(stock => stock.id === record.id);
    this.stocks.splice(index, 1);
  }


  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stocks${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: Stock): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stocks`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=store,asset,material,product,vehicle,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: Stock, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stocks/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=store,asset,material,product,vehicle,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: Stock): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stocks/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: Stock): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/erp/stocks/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

}
