import { Staff } from '../people/staff';
import { BlogComment } from './blog-comment';

export class Blog {
    id: string;
    surname: string;
    otherName: string;
    title: string;
    body: string;
    tags: string;
    author: Staff; 
    slug: string;
    isPublished: string;
    comments: BlogComment[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Blog {
    id: string;
    surname: string;
    otherName: string;
    title: string;
    body: string;
    tags: string;
    author: Staff; 
    slug: string;
    isPublished: string;
    comments: BlogComment[];
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

}
