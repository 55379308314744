import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { PmtSchedule, ApiResponse, Staff, PmtTerminal } from '../../models';
import { ApiService, EnvService, AuthService } from '../../services';
import {deepPropsExist, ID, nextDate} from '../../helpers';


@Injectable()
export class PmtSchedules {

    records: Array<PmtSchedule> = [];
    terminalSchedules: Array<PmtSchedule> = [];
    currentSchedule: PmtSchedule;
    today = new Date().toISOString().slice(0, 10);
    user: Staff;
    terminalId = PmtTerminal['id'];

    constructor(private apiService: ApiService,
                authService: AuthService,
                private datePipe: DatePipe,
                private env: EnvService) {
        this.user = authService.getUser();
        this.terminalId = this.user.terminal.id;
        // ! Default for Terminal
        this.getScheduleByTerminal().then().catch(err => console.log(err));
        // * Default for ERP
        let queryString = `?sort=-createdAt&createdAt>${this.today}`;
        if (this.terminalId !== ID.EMENE_TERMINAL) queryString += `&pmtTerminal=${this.user.terminal.id}`;
        queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminals,pmtVehicle,pmtRoutes,pmtPartner`;
        queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.records = res.payload; });
    }

    query(params?: any) {
        if (!params) {
            return this.records;
        }
        return this.records.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmtSchedule) {
        this.records.push(record);
    }

    delete(record: PmtSchedule) {
        this.records.splice(this.records.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: PmtSchedule): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminal,pmtVehicle,pmtRoute,pmtPartner`;
                    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmtSchedule, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminal,pmtVehicle,pmtRoute,partner`;
                    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmtSchedule): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmtSchedule): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }


    // * ==================== OPERATIONS =======================

    getPmtScheduleName(record: PmtSchedule): string {
        try {
            const { boardingDate, pmtRoutes, pmtVehicle } = record;
            const pmtRouteName = pmtRoutes.map(option => ` ~${option.name}~ `);
            const vehicleName = pmtVehicle ? (pmtVehicle.name ? pmtVehicle.name : '') : '';
            const dateFormat = boardingDate ? this.datePipe.transform(boardingDate, 'EE, MMM dd, hh:mm a') : '';
            return `PMT ${vehicleName} ${pmtRouteName}  ${dateFormat}`;
        } catch (err) {
            console.log('Error :: getScheduleName()' + err.message);
        }
    }

    async getScheduleByTerminal(queryString: string = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/terminal/${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => {
                this.terminalSchedules = res.payload;
                return res;
            }));
        return await proRes.toPromise();
    }

    async getScheduleByRoute(routeId: string, boardingDate = this.today): Promise<ApiResponse> {
        console.log('Boarding date => ', boardingDate, 'route => ', routeId);
        const str = `boardingDate=${boardingDate}&pmtRoute=${routeId}`;
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/route?${str}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => {
                this.terminalSchedules = res.payload;
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordAdd(payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules`;
        payload.terminal = this.terminalId;
        const proRes = this.apiService.postApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success) {
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminal,pmtVehicle,pmtRoute,pmtPartner`;
                    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,packagedBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                } else {
                    throwError(res.message);
                }
                return res;
            }));
        return await proRes.toPromise();
    }


    async updateOperation(pmtSchedule: PmtSchedule, payload): Promise<ApiResponse> {
        // check if object has _id and change it to id
        // tslint:disable-next-line:no-unused-expression
        deepPropsExist(pmtSchedule, '_id') ? pmtSchedule.id = pmtSchedule._id : '';
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/operation/${pmtSchedule.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success) {
                    this.delete(pmtSchedule);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminal,pmtVehicle,pmtRoute,pmtPartner`;
                    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,packagedBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                } else {
                    throwError(res.message);
                }
                return res;
            }));
        return await proRes.toPromise();
    }


    async busLoadingStatus(pmtBooking: PmtSchedule, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pmt/pmt-schedules/operation/${pmtBooking.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success) {
                    this.delete(pmtBooking);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=pmtReservations,pmtBoardings,pmtTerminal,pmtVehicle,pmtRoute,pmtPartner`;
                    queryString += `,boardedBy,loadedBy,arrivedBy,departedBy,waybilledBy,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                } else {
                    throwError(res.message);
                }
                return res;
            }));
        return await proRes.toPromise();
    }

}
