export type pmlData = {
    date: string;
    income: {
      gateway: any;
      type: string;
      children: [];
      direction: string;
      pmlRoutings: [];
      costEstimate: number;
      costPayable: number;
      paymentStatus: string;
      deliveryType: string;
      isReturned: boolean;
      packaged: boolean;
      deliveryStatus: string;
      routing: string;
      recipientConfirm: false;
      name: string;
      sender: string;
      recipient: string;
      packaging: string;
      description: string;
      terminalTo: string;
      departureDate: string;
      expectedDate: string;
      paymentMethod: string;
      identification: string;
      items: {
        id: string;
        name: string;
        quantity: number;
        mass: 1;
        volume: 1;
        category: string;
        worth: number;
        code: string;
      }[];
  
      terminalFrom: string;
      createdBy: string;
      creator: string;
      code: string;
      stateFrom: string;
      stateTo: string;
      locationFrom: {
        type: string;
        coordinates: number[];
        id: string;
        address: string;
      };
      locationTo: {
        type: string;
        coordinates: number[];
        id: string;
        address: string;
      };
      terminalStore: string;
      distance: number;
      duration: number;
      urgency: number;
      mass: number;
      volume: number;
      worth: number;
      quantity: number;
      senderPhone: string;
      senderEmail: string;
      recipientPhone: string;
      recipientEmail: string;
      trxref: string;
      createdAt: string;
      updatedAt: string;
      packagedBy: string;
      packagedDate: string;
      pmlPackage: string;
      id: string;
    }[];
  
    expense: {
      auditedStatus: string;
      terminal: string;
      subsidiary: string;
      code: string;
      expenseDate: string;
      amount: number;
      createdBy: string;
      voucher: string;
      createdAt: string;
      updatedAt: string;
      id: string;
    }[];
  }[];
  
  export const Pmldata: pmlData = [
    {
      date: "2023-06-23",
      income: [
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 21277,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing:
            "Parcel is being routed from Abuja, Utako, to Abakaliki, 25A, Afikpo Road Highway",
          recipientConfirm: false,
          name: "PERFUMES IN A CARTON",
          sender: "63c8e38f68244ff476fa6c96",
          recipient: "646e263d7f1bf6c2a95c429b",
          packaging: "CARTON",
          description: "PERFUMES IN A CARTON",
          terminalTo: "5ead489c4ac7923f5ea9b901",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-26T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "Other Identification",
          items: [
            {
              id: "64953e9a5eb68f6778487461",
              name: "PERFUMES IN A CARTON",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "609a631477fb140c80ba5ecf",
              worth: 10000,
              code: "3174211450-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "612df3bbd79b1d74b0fc428f",
          creator: "S",
          code: "3174211450",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000011",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [8.107396999999999, 6.314120399999999],
            id: "61dda67c1d41fb536be6eb32",
            address: "25 Afikpo Road, 480101, Abakaliki, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 416.371,
          duration: 7.43306,
          urgency: 0.13,
          mass: 1,
          volume: 1,
          worth: 10000,
          quantity: 1,
          senderPhone: "09065481637",
          senderEmail: "",
          recipientPhone: "09060364347",
          recipientEmail: "",
          trxref: "pml-64953e9a5eb68f677848745f-3174211450-parcel",
          createdAt: "2023-06-23T06:41:30.597Z",
          updatedAt: "2023-06-23T12:47:07.704Z",
          packagedBy: "6170076558e3c012b4b58945",
          packagedDate: "2023-06-23T11:59:16.473Z",
          pmlPackage: "649589145eb68f677848ccca",
          id: "64953e9a5eb68f677848745f",
        },
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 33992,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing: "Parcel is being routed from Abuja, Utako, to Lagos, Mazamaza",
          recipientConfirm: false,
          name: "KEY IN A BR/E",
          sender: "649540e7ea4ad4674ac9a16c",
          recipient: "64954052ea4ad4674ac9a142",
          packaging: "PACK",
          description: "KEY IN A BR/E         ",
          terminalTo: "5c51bc91860d8b5bc0000028",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-27T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "National ID",
          items: [
            {
              id: "649541d05eb68f67784878cc",
              name: "KEY IN A BR/E",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "60702661ca036f337a921d98",
              worth: 15000,
              code: "3174211453-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "60686270847f6c71c1a1bcb2",
          creator: "S",
          code: "3174211453",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000025",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [3.3633264, 6.5022366],
            id: "611a53a3761d983d5abed4c2",
            address: "9 Western Ave, Yaba 101241, Lagos, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 766.356,
          duration: 10.82111,
          urgency: 0.134,
          mass: 1,
          volume: 1,
          worth: 15000,
          quantity: 1,
          senderPhone: "08133149873",
          senderEmail: "",
          recipientPhone: "08145880268",
          recipientEmail: "",
          trxref: "pml-649541d05eb68f67784878ca-3174211453-parcel",
          createdAt: "2023-06-23T06:55:12.819Z",
          updatedAt: "2023-06-24T09:37:16.730Z",
          packagedBy: "612df3bbd79b1d74b0fc428f",
          packagedDate: "2023-06-24T09:36:43.602Z",
          pmlPackage: "6496b92b5eb68f6778495e7f",
          id: "649541d05eb68f67784878ca",
        },
      ],
      expense: [
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 2500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 4500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 5500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
      ],
    },
  
    {
      date: "2023-06-24",
      income: [
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 21277,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing:
            "Parcel is being routed from Abuja, Utako, to Abakaliki, 25A, Afikpo Road Highway",
          recipientConfirm: false,
          name: "PERFUMES IN A CARTON",
          sender: "63c8e38f68244ff476fa6c96",
          recipient: "646e263d7f1bf6c2a95c429b",
          packaging: "CARTON",
          description: "PERFUMES IN A CARTON",
          terminalTo: "5ead489c4ac7923f5ea9b901",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-26T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "Other Identification",
          items: [
            {
              id: "64953e9a5eb68f6778487461",
              name: "PERFUMES IN A CARTON",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "609a631477fb140c80ba5ecf",
              worth: 10000,
              code: "3174211450-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "612df3bbd79b1d74b0fc428f",
          creator: "S",
          code: "3174211450",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000011",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [8.107396999999999, 6.314120399999999],
            id: "61dda67c1d41fb536be6eb32",
            address: "25 Afikpo Road, 480101, Abakaliki, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 416.371,
          duration: 7.43306,
          urgency: 0.13,
          mass: 1,
          volume: 1,
          worth: 10000,
          quantity: 1,
          senderPhone: "09065481637",
          senderEmail: "",
          recipientPhone: "09060364347",
          recipientEmail: "",
          trxref: "pml-64953e9a5eb68f677848745f-3174211450-parcel",
          createdAt: "2023-06-23T06:41:30.597Z",
          updatedAt: "2023-06-23T12:47:07.704Z",
          packagedBy: "6170076558e3c012b4b58945",
          packagedDate: "2023-06-23T11:59:16.473Z",
          pmlPackage: "649589145eb68f677848ccca",
          id: "64953e9a5eb68f677848745f",
        },
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 33992,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing: "Parcel is being routed from Abuja, Utako, to Lagos, Mazamaza",
          recipientConfirm: false,
          name: "KEY IN A BR/E",
          sender: "649540e7ea4ad4674ac9a16c",
          recipient: "64954052ea4ad4674ac9a142",
          packaging: "PACK",
          description: "KEY IN A BR/E         ",
          terminalTo: "5c51bc91860d8b5bc0000028",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-27T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "National ID",
          items: [
            {
              id: "649541d05eb68f67784878cc",
              name: "KEY IN A BR/E",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "60702661ca036f337a921d98",
              worth: 15000,
              code: "3174211453-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "60686270847f6c71c1a1bcb2",
          creator: "S",
          code: "3174211453",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000025",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [3.3633264, 6.5022366],
            id: "611a53a3761d983d5abed4c2",
            address: "9 Western Ave, Yaba 101241, Lagos, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 766.356,
          duration: 10.82111,
          urgency: 0.134,
          mass: 1,
          volume: 1,
          worth: 15000,
          quantity: 1,
          senderPhone: "08133149873",
          senderEmail: "",
          recipientPhone: "08145880268",
          recipientEmail: "",
          trxref: "pml-649541d05eb68f67784878ca-3174211453-parcel",
          createdAt: "2023-06-23T06:55:12.819Z",
          updatedAt: "2023-06-24T09:37:16.730Z",
          packagedBy: "612df3bbd79b1d74b0fc428f",
          packagedDate: "2023-06-24T09:36:43.602Z",
          pmlPackage: "6496b92b5eb68f6778495e7f",
          id: "649541d05eb68f67784878ca",
        },
      ],
      expense: [
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 2500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 4500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 5500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
      ],
    },
    {
      date: "2023-06-25",
      income: [
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 21277,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing:
            "Parcel is being routed from Abuja, Utako, to Abakaliki, 25A, Afikpo Road Highway",
          recipientConfirm: false,
          name: "PERFUMES IN A CARTON",
          sender: "63c8e38f68244ff476fa6c96",
          recipient: "646e263d7f1bf6c2a95c429b",
          packaging: "CARTON",
          description: "PERFUMES IN A CARTON",
          terminalTo: "5ead489c4ac7923f5ea9b901",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-26T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "Other Identification",
          items: [
            {
              id: "64953e9a5eb68f6778487461",
              name: "PERFUMES IN A CARTON",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "609a631477fb140c80ba5ecf",
              worth: 10000,
              code: "3174211450-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "612df3bbd79b1d74b0fc428f",
          creator: "S",
          code: "3174211450",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000011",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [8.107396999999999, 6.314120399999999],
            id: "61dda67c1d41fb536be6eb32",
            address: "25 Afikpo Road, 480101, Abakaliki, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 416.371,
          duration: 7.43306,
          urgency: 0.13,
          mass: 1,
          volume: 1,
          worth: 10000,
          quantity: 1,
          senderPhone: "09065481637",
          senderEmail: "",
          recipientPhone: "09060364347",
          recipientEmail: "",
          trxref: "pml-64953e9a5eb68f677848745f-3174211450-parcel",
          createdAt: "2023-06-23T06:41:30.597Z",
          updatedAt: "2023-06-23T12:47:07.704Z",
          packagedBy: "6170076558e3c012b4b58945",
          packagedDate: "2023-06-23T11:59:16.473Z",
          pmlPackage: "649589145eb68f677848ccca",
          id: "64953e9a5eb68f677848745f",
        },
        {
          gateway: {},
          type: "SINGLE",
          children: [],
          direction: "F",
          pmlRoutings: [],
          costEstimate: 33992,
          costPayable: 3000,
          paymentStatus: "SUCCESSFUL",
          deliveryType: "TERMINAL",
          isReturned: false,
          packaged: true,
          deliveryStatus: "PACKAGED",
          routing: "Parcel is being routed from Abuja, Utako, to Lagos, Mazamaza",
          recipientConfirm: false,
          name: "KEY IN A BR/E",
          sender: "649540e7ea4ad4674ac9a16c",
          recipient: "64954052ea4ad4674ac9a142",
          packaging: "PACK",
          description: "KEY IN A BR/E         ",
          terminalTo: "5c51bc91860d8b5bc0000028",
          departureDate: "2023-06-24T00:00:00.000Z",
          expectedDate: "2023-06-27T09:00:00.000Z",
          paymentMethod: "CASH",
          identification: "National ID",
          items: [
            {
              id: "649541d05eb68f67784878cc",
              name: "KEY IN A BR/E",
              quantity: 1,
              mass: 1,
              volume: 1,
              category: "60702661ca036f337a921d98",
              worth: 15000,
              code: "3174211453-0",
            },
          ],
          terminalFrom: "5c51bc91860d8b5bc0000021",
          createdBy: "60686270847f6c71c1a1bcb2",
          creator: "S",
          code: "3174211453",
          stateFrom: "5851bc91860d8b5a70000015",
          stateTo: "5851bc91860d8b5a70000025",
          locationFrom: {
            type: "Point",
            coordinates: [7.4406072, 9.068232499999999],
            id: "611a53a1761d983d5abed45f",
            address: "39 Ajose Adeogun St, Mabushi 900108, Abuja, Nigeria",
          },
          locationTo: {
            type: "Point",
            coordinates: [3.3633264, 6.5022366],
            id: "611a53a3761d983d5abed4c2",
            address: "9 Western Ave, Yaba 101241, Lagos, Nigeria",
          },
          terminalStore: "5c51bc91860d8b5bc0000021",
          distance: 766.356,
          duration: 10.82111,
          urgency: 0.134,
          mass: 1,
          volume: 1,
          worth: 15000,
          quantity: 1,
          senderPhone: "08133149873",
          senderEmail: "",
          recipientPhone: "08145880268",
          recipientEmail: "",
          trxref: "pml-649541d05eb68f67784878ca-3174211453-parcel",
          createdAt: "2023-06-23T06:55:12.819Z",
          updatedAt: "2023-06-24T09:37:16.730Z",
          packagedBy: "612df3bbd79b1d74b0fc428f",
          packagedDate: "2023-06-24T09:36:43.602Z",
          pmlPackage: "6496b92b5eb68f6778495e7f",
          id: "649541d05eb68f67784878ca",
        },
      ],
      expense: [
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 2500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 4500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
  
        {
          auditedStatus: "PENDING",
          terminal: "5c51bc91860d8b5bc0000021",
          subsidiary: "PML",
          code: "3174211635",
          expenseDate: "2023-06-23T14:54:41.131Z",
          amount: 5500,
          createdBy: "5a51bc91860d8b5ba0158641",
          voucher: "6495b23188591d0f64f471a7",
          createdAt: "2023-06-23T14:54:41.566Z",
          updatedAt: "2023-06-23T14:54:41.566Z",
          id: "6495b23188591d0f64f471aa",
        },
      ],
    },
  ];
  