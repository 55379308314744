import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmtHiring, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';


@Injectable()
export class PmtHirings {

  private records: PmtHiring[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?`;
    queryString += `populate=pmtTerminalFrom,pmtTerminalTo,category,createdBy,updatedBy&sort=-createdAt&limit=200`;
    this.recordRetrieve(queryString).then(res => { this.records = res.payload;  });
  }

  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: PmtHiring) {
    this.records.push(record);
  }

  delete(record: PmtHiring) {
    const index = this.records.findIndex(route => route.id === record.id);
    this.records.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-hirings${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmtHiring): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-hirings`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminalFrom,terminalTo,category,createdBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: PmtHiring, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-hirings/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=pmtTerminalFrom,pmtTerminalTo,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmtHiring): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-hirings/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmtHiring): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pmt/pmt-hirings/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

}
