import { Store } from './store';
import { Asset } from '../finance/asset';
import { Material } from '../factory/material';
import { FactoryProduct } from '../factory/factory-product';
import { Vehicle } from '../factory/vehicle';
import { Staff } from '../people/staff';
import { Category } from './category';

export class Transfer {
    id: string;
    code: string;
    type: 'VEHICLE'|'PRODUCT'|'ASSET'| 'MATERIAL';
    description: string;
    item: string;
    asset: Asset;
    material: Material;
    product: FactoryProduct;
    vehicle: Vehicle;
    quantity: number;
    sender: Staff;
    recipient: Staff;
    sendingDate: Date;
    receivingDate: Date;
    storeFrom: Store;
    storeTo: Store;
    senderRemark: string;
    recipientRemark: string;
    transferStatus: string;;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}

export interface Transfer {
    id: string;
    code: string;
    type: 'VEHICLE'|'PRODUCT'|'ASSET'| 'MATERIAL';
    description: string;
    item: string;
    asset: Asset;
    material: Material;
    product: FactoryProduct;
    vehicle: Vehicle;
    quantity: number;
    sender: Staff;
    recipient: Staff;
    sendingDate: Date;
    receivingDate: Date;
    storeFrom: Store;
    storeTo: Store;
    senderRemark: string;
    recipientRemark: string;
    transferStatus: string;
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
