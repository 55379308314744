import { Terminal } from '../location';
import { Bank } from './bank';
import { Staff } from '../people';
import { Category } from '../general';

export class PosAccount {
    id: string;
    category: Category;
    subsidiary: string;
    terminal: Terminal;
    bank: Bank;
    merchantId: string;
    merchantName: string;
    serviceOfficer: string;
    serviceOfficerPhone: string;
    physicalAddr: string;
    posTerminalId: string;
    bankAccNo: string;
    accountName: string;
    requestingBranchCode: string;
    requestingBranch: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;


    constructor(fields: any) {
        Object.keys(fields).forEach(key => {
            this[key] = fields[key];
        });
    }
}
export interface PosAccount {
    id: string;
    category: Category;
    subsidiary: string;
    terminal: Terminal;
    bank: Bank;
    merchantId: string;
    merchantName: string;
    serviceOfficer: string;
    serviceOfficerPhone: string;
    physicalAddr: string;
    posTerminalId: string;
    bankAccNo: string;
    accountName: string;
    requestingBranchCode: string;
    requestingBranch: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
