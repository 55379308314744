import { Vehicle } from '../factory/vehicle';
import { Partner } from '../people/partner';
import { Fault } from './fault';
import { Stage } from '../general/stage';
import { Team } from '../general/team';
import { Staff } from '../people/staff';
import { Sale } from '../ecommerce/sale';
import { Category } from '../general/category';
import { FactoryProduct } from '../factory/factory-product';

export class Maintenance {
    id: string;
    code: string;
    category: Category;
    registerDate: Date;
    vehicle: Vehicle;
    partner: Partner;
    complaint: string;
    team: Team;
    diagnosis: string;
    faults: Array<Fault>;
    saleOrders: Array<Sale>;
    spares: Array<FactoryProduct>;
    stage: Stage;
    partnerRemark: string;
    certifiedBy: string;
    certifiedDate:	Date;
    certifiedRemark:	string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface PmtMaintenance {
    id: string;
    code: string;
    category: Category;
    registerDate: Date;
    vehicle: Vehicle;
    partner: Partner;
    complaint: string;
    team: Team;
    diagnosis: string;
    faults: Array<Fault>;
    saleOrders: Array<Sale>;
    spares: Array<FactoryProduct>;
    stage: Stage;
    partnerRemark: string;
    certifiedBy: string;
    certifiedDate:	Date;
    certifiedRemark:	string;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
