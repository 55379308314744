import { Sale } from './sale';
import { Cart } from './cart';
import { Rating } from '../crm';
import { Product } from '.';
import { Category } from '../general';
import {County, State} from '../location';

export class Merchant {
    id: string;
    code: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: boolean;
    contactPerson: string;
    contactPersonPhone: string;
    products: Product[];
    photo: string;
    referral: string;   //lead referral source
    address: string;
    country: string;
    website: string;
    isEngrClient: boolean;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    company: string;
    industry: string;
    state: State;
    county: County;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    ratings: Rating[];
    sales: Sale[];
    category: Category;
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    businessName: string;
    businessLogo: string;
    businessBanner: string;
    businessEmail: string;
    businessPhone: string;
    businessAddress: string;
    businessSlogan: string;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Merchant {
    id: string;
    code: string;
    title: string;
    surname: string;
    otherName: string;
    gender: string;
    birthDate: Date;
    phone: string;
    email: string;
    password: string;
    otp: string;
    otpCount: number;
    otpAccess: boolean;
    contactPerson: string;
    contactPersonPhone: string;
    products: Product[];
    photo: string;
    referral: string;   //lead referral source
    address: string;
    country: string;
    website: string;
    isEngrClient: boolean;
    isPmtClient?: boolean;
    isPmlClient: boolean;
    isPetClient: boolean;
    isShopClient: boolean;
    isTenant: boolean;
    company: string;
    industry: string;
    state: State;
    county: County;
    isStaff: boolean;
    wallet: number;
    cart: Cart;
    ratings: Rating[];
    sales: Sale[];
    category: Category;
    isPhoneVerified: boolean;
    isEmailVerified: boolean;
    businessName: string;
    businessLogo: string;
    businessBanner: string;
    businessEmail: string;
    businessPhone: string;
    businessAddress: string;
    businessSlogan: string;
    bankName: string;
    bankAccountNumber: string;
    bankAccountName: string;
    skype?: string;
    linkedin?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    youtube?: string;
    points?: number;
    remark?: string;
}
