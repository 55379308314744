import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../services";
import { Staff } from "../../models/people";
import { getFullname } from "../../helpers";
import { PmtReportModule } from "src/app/pages/pmt/pmt-report/pmt-report.module";

const misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "tech_tablet",
  },
  {
    path: "/customers",
    title: "Customers",
    type: "link",
    icontype: "users_single-02",
  },
  {
    path: "/accounts",
    title: "Accounts",
    type: "link",
    icontype: "business_money-coins",
  },
  {
    path: "/bank-register",
    title: "Bank Registers",
    type: "link",
    icontype: "business_money-coins",
  },
  // {
  //   path: "/bulk-report",
  //   title: "Bulk Reports",
  //   type: "link",
  //   icontype: "tech_tablet",
  // },
  // {
  //   path: "bulk-report",
  //   title: "Terminal_Report",
  //   icontype: "tech_tablet",
  //   ab: "R",
  // },
  {
    path: "/pmt",
    title: "PMT",
    type: "sub",
    icontype: "design_bullet-list-67",
    collapse: "pmt",
    isCollapsed: true,
    children: [
      { path: "boarding", title: "Pmt Boardings", ab: "PB" },
      { path: "reservations", title: "Pmt Reservations", ab: "PR" },
      // { path: "report", title: "Pmt Report", ab: "PR" },
      // { path: "report", title: "Daily Report", ab: "DR" },
      { path: "reports", title: "Report", ab: "R" },
      { path: "bulk-report", title: "Bulk-Report", ab: "BR" },
    ],
  },
  {
    path: "/pml",
    title: "PML",
    type: "sub",
    icontype: "design_bullet-list-67",
    collapse: "pml",
    isCollapsed: true,
    children: [
      { path: "parcels", title: "Parcels", ab: "P" },
      { path: "report", title: "Daily Report", ab: "DR" },
      { path: "bulk-report", title: "Bulk-Report", ab: "R" },
    ],
  },
  {
    path: "/enterprise",
    title: "Entreprise",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/income",
    title: "Income",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/expenses",
    title: "Expense",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/voucher",
    title: "Voucher",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/pmt-manifest",
    title: "Pmt Manifest",
    type: "link",
    icontype: "design_bullet-list-67",
  },
  {
    path: "/gateway-payments",
    title: "Gateway Payments",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/budget",
    title: "Budget",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/pmt-agent",
    title: "Pmt Agents",
    type: "link",
    icontype: "business_chart-bar-32",
  },
  {
    path: "/merchants",
    title: "Merchants",
    type: "link",
    icontype: "education_atom",
  },

  {
    path: "/user-profile",
    title: "User Profile",
    type: "link",
    icontype: "business_badge",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public user: Staff;
  getFullName = getFullname;

  constructor(private toastr: ToastrService, private authService: AuthService) {
    this.user = this.authService.getUser();
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  sleep(milliseconds) {
    const start = new Date().getTime();
    for (let i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      let element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        const style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        const style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
      this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add("sidebar-mini");
      this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }
}
