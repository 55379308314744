import { Staff } from '../people/staff';
import { Category } from '../general';

// TODO

export class Invoice {
    id: string;
    code: string;
    title: string;
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }
}

export interface Invoice {
    id: string;
    code: string;
    title: string;
    category: Category;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}
