
interface FormObject {
    fieldName?: string;
    dataType?: any;
}

enum REPORT_SCOPE {
    // ["STAFF" "ZONE", "REGION", "TERMINAL", "SUBSIDIARY"],
    STAFF = "STAFF",
    ZONE = "ZONE",
    REGION = "REGION",
    TERMINAL = "TERMINAL",
    SUBSIDIARY = "SUBSIDIARY",
}

export interface AnalyticReport {
    _id: string;
    id: string;
    code: string;
    type: string;
    title: string;
    url?: string;
    description: string;
    scope: REPORT_SCOPE;
    subsidiary: 'PMT' | 'PML';
    entity: string;
    inputs: FormObject[];
    outputs?: FormObject[];
    aggregates?: FormObject[];
}

export class AnalyticReport {
    _id: string;
    id: string;
    code: string;
    type: string;
    title: string;
    url?: string;
    description: string;
    scope: REPORT_SCOPE;
    subsidiary: 'PMT' | 'PML';
    entity: string;
    inputs: FormObject[];
    outputs?: FormObject[];
    aggregates?: FormObject[];

    constructor(fields: any){
        for(let f of fields){
            this[f] = fields[f];
        }
    }
}