import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as printJS from 'print-js';
import { PmtManifest } from '../models';
import {Receipt} from '../models/finance';
import {getFullname} from '../helpers';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss']
})
export class ManifestComponent implements OnInit, AfterViewInit {
  @ViewChild('printArea', {static: false})  printArea: ElementRef;
  show = false;
  today = new Date().toISOString().slice(0, 10);
  getFullName = getFullname;
  data: PmtManifest[] = [
    {
      vehicle: '',
      partner: '',
      route: '',
      customerRoute: '',
      customerName: '',
      customerPhone: '',
      contactPerson: '',
      contactPersonPhone: '',
      amount: 0,
      destination: '',
      seats: []
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('Values on ngAfterViewInit():');
    console.log('printArea: ', this.printArea.nativeElement);
  }

  print(printData: PmtManifest[]): void {
    this.data = printData;
    this.show = true;
    setTimeout(
      () => {
        printJS({printable: this.printArea.nativeElement.outerHTML, type: 'raw-html'});
        this.show = false;
      }, 3
    );
  }

}
