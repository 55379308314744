import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlReport } from './../../models/pml/pml-report';
import { ApiService, EnvService } from '../../services';
import { ApiResponse } from '../../../app/models';


@Injectable()
export class PmlReports {

  private records: PmlReport[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
    // this.recordRetrieve(queryString).then(res => { this.records = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.records;
    }
    return this.records.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: PmlReport) {
    this.records.push(record);
  }

  delete(record: PmlReport) {
    const index = this.records.findIndex(route => route.id === record.id);
    this.records.splice(index, 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: PmlReport): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminalFrom,terminalTo,category,createdBy`;
          // this.recordRetrieve(queryString).then(res => {
          //   this.add(res.payload[0])
          // })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async generateReport(payload: any): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate`;
    const proRes = this.apiService.postApi(url, payload).pipe(
      map((res: ApiResponse) => res)
    );
    return proRes.toPromise();
  }

  async recordUpdate(record: PmlReport, payload): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=terminalFrom,terminalTo,category,createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0]);
          });
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: PmlReport): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: PmlReport): Promise<ApiResponse> {
    const url = `${this.env.getCurrentUrl()}/pml/reports/generate/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

}
