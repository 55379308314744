import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AnalyticReport, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable({
    providedIn: 'root'
})

export class AnalyticsReports {
    categories: AnalyticReport[] = [];

  constructor(private apiService: ApiService, private env: EnvService) {
    let queryString = `?sort=-createdAt`;
    queryString += `&populate=createdBy,updatedBy`;
    this.recordRetrieve(queryString).then(res => { this.categories = res.payload; console.log(res) });
  }

  query(params?: any) {
    if (!params) {
      return this.categories;
    }
    return this.categories.filter((item) => {
      for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const field = item[key];
            if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
              return item;
            } else if (field === params[key]) {
              return item;
            }
          }
      }
      return null;
    });
  }

  add(record: AnalyticReport) {
    this.categories.push(new AnalyticReport(record));
  }

  delete(record: AnalyticReport) {
    this.categories.splice(this.categories.indexOf(record), 1);
  }

  async recordRetrieve(queryString = ''): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/reports/${queryString}`;
    const proRes = this.apiService.getApi(url).pipe(
      map((res: ApiResponse) => res));
    return await proRes.toPromise();
  }

  async recordCreate(record: AnalyticReport): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/reports`;
    const proRes = this.apiService.postApi(url, record).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          console.log('recordCreate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordUpdate(record: AnalyticReport, payload): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/reports/${record.id}`;
    const proRes = this.apiService.updateApi(url, payload).pipe(
      map((res: ApiResponse) => {
        if (res.success && res.payload) {
          this.delete(record);
          console.log('recordUpdate() successful =>', res.payload);
          let queryString = `?_id=${res.payload.id}`;
          queryString += `&populate=createdBy,updatedBy`;
          this.recordRetrieve(queryString).then(res => {
            this.add(res.payload[0])
          })
        }
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordPatch(record: AnalyticReport): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/reports/${record.id}`;
    const proRes = this.apiService.patchApi(url, {}).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }

  async recordDelete(record: AnalyticReport): Promise<ApiResponse> {
    const url = `${this.env.API_URL}/erp/reports/${record.id}`;
    const proRes = this.apiService.deleteApi(url).pipe(
      map((res: ApiResponse) => {
        this.delete(record);
        return res;
      }));
    return await proRes.toPromise();
  }
}