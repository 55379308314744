import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PmlZone, ApiResponse } from '../../models';
import { ApiService, EnvService } from '../../services';

@Injectable()
export class PmlZones {

    private records: PmlZone[] = [];

    constructor(private apiService: ApiService, private env: EnvService) {
        let queryString = `?sort=name`;
        queryString += `&populate=counties,manager,supervisor,createdBy,updatedBy`;
        this.recordRetrieve(queryString).then(res => { this.records = res.payload; });
    }

    query(params?: any) {
        if (!params) {
            return this.records;
        }
        return this.records.filter((item) => {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const field = item[key];
                    if (typeof field === 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
                        return item;
                    } else if (field === params[key]) {
                        return item;
                    }
                }
            }
            return null;
        });
    }

    add(record: PmlZone) {
        this.records.push(record);
    }

    delete(record: PmlZone) {
        this.records.splice(this.records.indexOf(record), 1);
    }

    async recordRetrieve(queryString = ''): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-zones${queryString}`;
        const proRes = this.apiService.getApi(url).pipe(
            map((res: ApiResponse) => res));
        return await proRes.toPromise();
    }

    async recordCreate(record: PmlZone): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-zones`;
        const proRes = this.apiService.postApi(url, record).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    console.log('recordCreate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=counties,manager,supervisor,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordUpdate(record: PmlZone, payload): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-zones/${record.id}`;
        const proRes = this.apiService.updateApi(url, payload).pipe(
            map((res: ApiResponse) => {
                if (res.success && res.payload) {
                    this.delete(record);
                    console.log('recordUpdate() successful =>', res.payload);
                    let queryString = `?_id=${res.payload.id}`;
                    queryString += `&populate=counties,manager,supervisor,createdBy,updatedBy`;
                    this.recordRetrieve(queryString).then(res => {
                        this.add(res.payload[0]);
                    });
                }
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordPatch(record: PmlZone): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-zones/${record.id}`;
        const proRes = this.apiService.patchApi(url, { deleted: true }).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }

    async recordDelete(record: PmlZone): Promise<ApiResponse> {
        const url = `${this.env.getCurrentUrl()}/pml/pml-zones/${record.id}`;
        const proRes = this.apiService.deleteApi(url).pipe(
            map((res: ApiResponse) => {
                this.delete(record);
                return res;
            }));
        return await proRes.toPromise();
    }
}
