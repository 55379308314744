import { Staff } from '../people/staff';
import { Category } from '../general/category';
import { OffenceType } from './offence-type';
import { Partner } from './partner';

export class Offence {
    id: string;
    code: string;
    category: Category;
    offenceType: OffenceType;
    offender: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    offence?: string;
    offenceDate?: Date;
    description?: string;
    offenceStatus?: "PENDING"|"ARBITRATED";
    verdict?: "INNOCENT"|"GUILTY";
    verdictBy?: Staff;
    verdictDate?: Date;
    verdictRemark?: string;
    fine: number;
    discipline?: "WARNING"|"SUSPENSION"|"DISMISSED";
    suspension?: string;
    payment: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;

    constructor(fields: any) {
        // Quick and dirty extend/assign fields to this model
        for (const f in fields) {
            // @ts-ignore
            this[f] = fields[f];
        }
    }

}

export interface Offence {
    // [prop: string]: any;
    id: string;
    code: string;
    category: Category;
    offenceType: OffenceType;
    offender: 'STAFF'|'PARTNER';
    staff: Staff;
    partner: Partner;
    offence?: string;
    offenceDate?: Date;
    description?: string;
    offenceStatus?: "PENDING"|"ARBITRATED";
    verdict?: "INNOCENT"|"GUILTY";
    verdictBy?: Staff;
    verdictDate?: Date;
    verdictRemark?: string;
    fine: number;
    discipline?: "WARNING"|"SUSPENSION"|"DISMISSED";
    suspension?: string;
    payment: string;
    createdBy?: Staff;
    createdAt?: Date;
    updatedBy?: Staff;
    updatedAt?: Date;
}

